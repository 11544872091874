import type { ApolloQueryResult } from '@apollo/client/core';
import { FdrProductOffer } from '@hotelplan/supergraph-api';
import { useFdrPageComponentsContext } from 'fdr/components/local/fdr-page-components/useFdrPageComponentsContext';
import { TQueryResultWithPatchedFetch } from 'fdr/components/local/fdr-recommendation-group/component-request/component-request.types';
import { fdrRecommendationProductImageCriteria } from 'fdr/components/util/criterias/recommendation-product-image.dmc';
import { FdrSmartSeerRecommendationComponentQuery } from 'fdr/schemas/query/component/fdr-smart-seer-recommendation-component.generated';
import {
  useFdrHomeSmartSeerRecommenderQuery,
  FdrHomeSmartSeerRecommenderQuery,
} from 'fdr/schemas/query/home/fdr-home-smart-seer-recommender.generated';
import { createInactiveProductFilter } from './component-request.utils';

const mapHomeSmartSeerRecommenderToSmartSeerRecommendationComponent = (
  res: ApolloQueryResult<FdrHomeSmartSeerRecommenderQuery>
): ApolloQueryResult<FdrSmartSeerRecommendationComponentQuery> => {
  return {
    ...res,
    data: {
      fdrComponents: {
        components: res?.data?.fdrHomePage?.smartSeerRecommender
          ? [
              {
                ...res?.data?.fdrHomePage?.smartSeerRecommender,
              },
            ]
          : [],
      },
    },
  };
};

export function useGetFdrHomeSmartSeerRecommendationComponent(): TQueryResultWithPatchedFetch {
  const context = useFdrPageComponentsContext();

  const result = useFdrHomeSmartSeerRecommenderQuery({
    variables: {
      ...context,
      productImageCriteria: fdrRecommendationProductImageCriteria,
    },
  });

  return {
    ...result,
    fetchMore(
      page: number
    ): Promise<ApolloQueryResult<FdrHomeSmartSeerRecommenderQuery>> {
      return result
        .fetchMore({
          variables: {
            smartSeerPage: page,
            productImageCriteria: fdrRecommendationProductImageCriteria,
            ...context,
          },
        })
        .then(prRes =>
          mapHomeSmartSeerRecommenderToSmartSeerRecommendationComponent(prRes)
        )
        .then(
          createInactiveProductFilter<
            FdrHomeSmartSeerRecommenderQuery,
            FdrProductOffer
          >(item => item.product)
        );
    },
  } as any;
}
