import React, { useMemo } from 'react';
import { trackRecommendationsLoaded } from '@hotelplan/libs.tracking';
import {
  FdrRecommendationGroupHeading,
  FdrGroupWrapper,
  FdrRecommendationGroupMainText,
} from 'fdr/components/local/fdr-recommendation-group/fdr-recommendation-group.styles';
import { IFdrRecommendationGroupCommonProps } from 'fdr/components/local/fdr-recommendation-group/fdr-recommendation-group.types';
import { getFdrRecommendationComponent } from 'fdr/components/local/fdr-recommendation-group/fdr-recommendation-group.utils';
import { mapFdrRecommendationsToTrackableData } from 'fdr/components/local/fdr-recommendation-group/fdr-recommendations.mappers';
import { FdrRecommendationList } from 'fdr/components/local/fdr-recommendation-list/fdr-recommendation-list';

export const FdrRecommendationGroupWithMedia: React.FC<IFdrRecommendationGroupCommonProps> =
  props => {
    const {
      groupName,
      items,
      tracking,
      mainText,
      fontColor,
      withoutTitle,
      className,
    } = props;

    const itemsLength = useMemo(() => items.length, [items]);

    const render = useMemo(
      () => getFdrRecommendationComponent(groupName, itemsLength),
      [groupName, itemsLength]
    );

    return (
      <FdrGroupWrapper className="group-wrapper" data-id="recommendation-group">
        {withoutTitle ? null : (
          <FdrRecommendationGroupHeading
            data-id="recommendation-group-heading"
            className={'group-heading'}
            fontColor={fontColor}
          >
            {groupName}
          </FdrRecommendationGroupHeading>
        )}

        {mainText ? (
          <FdrRecommendationGroupMainText
            data-id="recommendation-group-main-text"
            className="recommendation-group-main-text"
            dangerouslySetInnerHTML={{ __html: mainText }}
            fontColor={fontColor}
          />
        ) : null}
        <FdrRecommendationList
          className={className}
          onInit={showedRecommendations => {
            trackRecommendationsLoaded(
              mapFdrRecommendationsToTrackableData(
                groupName,
                showedRecommendations,
                tracking
              )
            );
          }}
          recommendations={items}
          render={render}
        />
      </FdrGroupWrapper>
    );
  };
