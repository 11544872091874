import type { ApolloQueryResult } from '@apollo/client/core';
import { FdrProductOffer } from '@hotelplan/supergraph-api';
import { useFdrPageComponentsContext } from 'fdr/components/local/fdr-page-components';
import { TQueryResultWithPatchedFetch } from 'fdr/components/local/fdr-recommendation-group/component-request/component-request.types';
import { fdrRecommendationProductImageCriteria } from 'fdr/components/util/criterias/recommendation-product-image.dmc';
import {
  useFdrSmartSeerRecommendationComponentQuery,
  FdrSmartSeerRecommendationComponentQuery,
} from 'fdr/schemas/query/component/fdr-smart-seer-recommendation-component.generated';
import { createInactiveProductFilter } from './component-request.utils';

export function useGetFdrSmartSeerRecommendationComponent(
  id: string
): TQueryResultWithPatchedFetch {
  const context = useFdrPageComponentsContext();

  const result = useFdrSmartSeerRecommendationComponentQuery({
    variables: {
      input: { ids: [id] },
      smartSeerPage: 0,
      productImageCriteria: fdrRecommendationProductImageCriteria,
      ...context,
    },
    skip: true,
  });

  return {
    ...result,
    fetchMore(
      page: number
    ): Promise<ApolloQueryResult<FdrSmartSeerRecommendationComponentQuery>> {
      return result
        .fetchMore({
          variables: {
            input: { ids: [id] },
            smartSeerPage: page,
            ...context,
          },
        })
        .then(
          createInactiveProductFilter<
            FdrSmartSeerRecommendationComponentQuery,
            FdrProductOffer
          >(item => item.product)
        );
    },
  };
}
