import type { ApolloQueryResult } from '@apollo/client/core';
import { FdrGeoOffer } from '@hotelplan/supergraph-api';
import { TQueryResultWithPatchedFetch } from 'fdr/components/local/fdr-recommendation-group/component-request/component-request.types';
import {
  FdrGeoOfferRecommendationComponentQuery,
  useFdrGeoOfferRecommendationComponentQuery,
} from 'fdr/schemas/query/component/fdr-geo-offer-recommendation-component.generated';
import { createInactiveProductFilter } from './component-request.utils';

export function useGetFdrGeoOfferRecommendationComponent(
  id: string
): TQueryResultWithPatchedFetch {
  const result = useFdrGeoOfferRecommendationComponentQuery({
    variables: {
      input: { ids: [id] },
      geoPage: 0,
    },
    skip: true,
  });

  return {
    ...result,
    fetchMore(
      page: number
    ): Promise<ApolloQueryResult<FdrGeoOfferRecommendationComponentQuery>> {
      return result
        .fetchMore({
          variables: {
            input: { ids: [id] },
            geoPage: page,
          },
        })
        .then(
          createInactiveProductFilter<
            FdrGeoOfferRecommendationComponentQuery,
            FdrGeoOffer
          >(item => item.topOffer.product)
        );
    },
  };
}
