import type * as Types from '@hotelplan/supergraph-api';

import type { DocumentNode } from 'graphql';
import { FdrImageFragmentDoc } from 'fdr/schemas/fragment/regular/fdr-image.generated';
import { FdrResizedFragmentDoc } from 'fdr/schemas/fragment/regular/fdr-resized.generated';
export type FdrNewsletterSignupFragment = {
  __typename?: 'FdrNewsletterSignup';
  id: string;
  bgColor?: string | null;
  buttonText: string;
  disclaimerText: string;
  mainText: string;
  successMessageText: string;
  successMessageTitle: string;
  title?: string | null;
  image?: {
    __typename?: 'FdrImage';
    alt: string;
    description?: string | null;
    copyright?: string | null;
    rawUrl: string;
    desaturated?: boolean | null;
    resized: Array<{
      __typename?: 'FdrResizedImage';
      id: string;
      link: {
        __typename?: 'FdrLink';
        openMethod?: Types.FdrLinkOpenMethod | null;
        legacyUrl?: string | null;
        type: Types.FdrLinkType;
        url: string;
      };
    }>;
  } | null;
  successMessageTextIcon?: {
    __typename?: 'FdrImage';
    alt: string;
    description?: string | null;
    copyright?: string | null;
    rawUrl: string;
    desaturated?: boolean | null;
    resized: Array<{
      __typename?: 'FdrResizedImage';
      id: string;
      link: {
        __typename?: 'FdrLink';
        openMethod?: Types.FdrLinkOpenMethod | null;
        legacyUrl?: string | null;
        type: Types.FdrLinkType;
        url: string;
      };
    }>;
  } | null;
};

export const FdrNewsletterSignupFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'fdrNewsletterSignup' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FdrNewsletterSignup' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bgColor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'buttonText' } },
          { kind: 'Field', name: { kind: 'Name', value: 'disclaimerText' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mainText' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'successMessageText' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'successMessageTitle' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'image' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'fdrImage' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'resized' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'criteria' },
                      value: {
                        kind: 'ListValue',
                        values: [
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'id' },
                                value: {
                                  kind: 'StringValue',
                                  value: '1x_mobile',
                                  block: false,
                                },
                              },
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'width' },
                                value: { kind: 'IntValue', value: '342' },
                              },
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'height' },
                                value: { kind: 'IntValue', value: '254' },
                              },
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'mode' },
                                value: { kind: 'EnumValue', value: 'AUTO' },
                              },
                            ],
                          },
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'id' },
                                value: {
                                  kind: 'StringValue',
                                  value: '1x_desktop',
                                  block: false,
                                },
                              },
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'width' },
                                value: { kind: 'IntValue', value: '400' },
                              },
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'height' },
                                value: { kind: 'IntValue', value: '400' },
                              },
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'mode' },
                                value: { kind: 'EnumValue', value: 'AUTO' },
                              },
                            ],
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'fdrResized' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'successMessageTextIcon' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'fdrImage' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'resized' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'criteria' },
                      value: {
                        kind: 'ListValue',
                        values: [
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'id' },
                                value: {
                                  kind: 'StringValue',
                                  value: '1x_mobile',
                                  block: false,
                                },
                              },
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'width' },
                                value: { kind: 'IntValue', value: '80' },
                              },
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'height' },
                                value: { kind: 'IntValue', value: '80' },
                              },
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'mode' },
                                value: { kind: 'EnumValue', value: 'AUTO' },
                              },
                            ],
                          },
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'id' },
                                value: {
                                  kind: 'StringValue',
                                  value: '1x_desktop',
                                  block: false,
                                },
                              },
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'width' },
                                value: { kind: 'IntValue', value: '80' },
                              },
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'height' },
                                value: { kind: 'IntValue', value: '80' },
                              },
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'mode' },
                                value: { kind: 'EnumValue', value: 'AUTO' },
                              },
                            ],
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'fdrResized' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...FdrImageFragmentDoc.definitions,
    ...FdrResizedFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;
