import React, { useMemo } from 'react';
import { trackRecommendationsLoaded } from '@hotelplan/libs.tracking';
import { FdrPagination } from '@hotelplan/supergraph-api';
import { TUnionPageComponents } from 'fdr/components/local/fdr-page-components/fdr-page-components.types';
import { useGetFdrComponent } from 'fdr/components/local/fdr-recommendation-group/component-request/useGetFdrComponent';
import {
  FdrRecommendationGroupHeading,
  FdrGroupWrapper,
  FdrRecommendationGroupMainText,
} from 'fdr/components/local/fdr-recommendation-group/fdr-recommendation-group.styles';
import { getFdrRecommendationComponent } from 'fdr/components/local/fdr-recommendation-group/fdr-recommendation-group.utils';
import { FdrRecommendationList } from 'fdr/components/local/fdr-recommendation-list/fdr-recommendation-list';
import { TQueryResultWithPatchedFetch } from './component-request/component-request.types';
import { IFdrRecommendationGroupCommonProps } from './fdr-recommendation-group.types';
import { mapFdrRecommendationsToTrackableData } from './fdr-recommendations.mappers';

interface IFdrRecommendationGroupProps
  extends IFdrRecommendationGroupCommonProps {
  pagination: FdrPagination;
  useGet: (id: string) => TQueryResultWithPatchedFetch | null;
  recommendationType?: TUnionPageComponents['__typename'];
}

export const FdrRecommendationGroup: React.FC<IFdrRecommendationGroupProps> =
  props => {
    const {
      groupName,
      items: initialItems,
      tracking,
      mainText,
      fontColor,
      pagination,
      withoutTitle,
      className,
      useGet,
      id,
      recommendationType,
    } = props;

    const { items, nextPage, hasMore, page } = useGetFdrComponent(
      id,
      initialItems,
      pagination,
      useGet
    );

    const itemsLength = useMemo(() => items.length, [items]);

    const render = useMemo(
      () =>
        getFdrRecommendationComponent(
          groupName,
          itemsLength,
          recommendationType
        ),
      [groupName, itemsLength, recommendationType]
    );

    return (
      <FdrGroupWrapper className="group-wrapper" data-id="recommendation-group">
        {withoutTitle ? null : (
          <FdrRecommendationGroupHeading
            data-id="recommendation-group-heading"
            className={'group-heading'}
            fontColor={fontColor}
          >
            {groupName}
          </FdrRecommendationGroupHeading>
        )}

        {mainText ? (
          <FdrRecommendationGroupMainText
            data-id="recommendation-group-main-text"
            className="recommendation-group-main-text"
            dangerouslySetInnerHTML={{ __html: mainText }}
            fontColor={fontColor}
          />
        ) : null}
        <FdrRecommendationList
          className={className}
          recommendationType={recommendationType}
          onInit={showedRecommendations => {
            trackRecommendationsLoaded(
              mapFdrRecommendationsToTrackableData(
                groupName,
                showedRecommendations,
                tracking
              )
            );
          }}
          recommendations={items}
          render={render}
          page={page}
          perPage={pagination.perPage}
          total={pagination.total}
          hasMore={hasMore}
          onFetchMore={nextPage}
        />
      </FdrGroupWrapper>
    );
  };
