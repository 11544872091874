import styled from 'styled-components';
import { sx2CssThemeFn } from '@hotelplan/util.theme.sxc';
import GroupHeading from 'components/domain/heading/GroupHeading';

export const FdrGroupWrapper = styled.div(
  sx2CssThemeFn({
    px: [4, 0],
    pb: '50px',
  })
);

export const FdrRecommendationGroupMainText = styled.div<{
  fontColor?: string;
}>(({ fontColor, theme: { FONT_SIZE } }) =>
  sx2CssThemeFn({
    ...FONT_SIZE.MEDIUM,
    pt: ['10px', 3],
    color: fontColor,
  })
);

export const FdrRecommendationGroupHeading = styled(GroupHeading)<{
  fontColor?: string;
}>(({ fontColor }) => ({ color: fontColor }));
