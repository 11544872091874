import type { ApolloQueryResult } from '@apollo/client/core';
import { FdrThemeOffer } from '@hotelplan/supergraph-api';
import { TQueryResultWithPatchedFetch } from 'fdr/components/local/fdr-recommendation-group/component-request/component-request.types';
import {
  useFdrThemeOfferRecommendationComponentQuery,
  FdrThemeOfferRecommendationComponentQuery,
} from 'fdr/schemas/query/component/fdr-theme-offer-recommendation-component.generated';
import { createInactiveProductFilter } from './component-request.utils';

export function useGetFdrThemeOfferRecommendationComponent(
  id: string
): TQueryResultWithPatchedFetch {
  const result = useFdrThemeOfferRecommendationComponentQuery({
    variables: {
      input: { ids: [id] },
      themePage: 0,
    },
    skip: true,
  });

  return {
    ...result,
    fetchMore(
      page: number
    ): Promise<ApolloQueryResult<FdrThemeOfferRecommendationComponentQuery>> {
      return result
        .fetchMore({
          variables: {
            input: { ids: [id] },
            themePage: page,
          },
        })
        .then(
          createInactiveProductFilter<
            FdrThemeOfferRecommendationComponentQuery,
            FdrThemeOffer
          >(item => item.offer.product)
        );
    },
  };
}
