import isEmpty from 'lodash/fp/isEmpty';
import omit from 'lodash/fp/omit';
import pipe from 'lodash/fp/pipe';
import dynamic from 'next/dynamic';
import React from 'react';
import { TUnionPageComponents } from 'fdr/components/local/fdr-page-components/fdr-page-components.types';
import { FdrBlogArticleRecommendationItemFragment } from 'fdr/schemas/fragment/recommenders/blog-article/fdr-blog-article-recommendation-item.generated';
import { FdrFlightOfferItemFragment } from 'fdr/schemas/fragment/recommenders/flight/fdr-flight-offer-recommendation-item.generated';
import { FdrFlightItemFragment } from 'fdr/schemas/fragment/recommenders/flight/fdr-flight-recommendation-item.generated';
import { FdrGeoOfferItemFragment } from 'fdr/schemas/fragment/recommenders/geo/fdr-geo-offer-item.generated';
import { FdrMarketingItemFragment } from 'fdr/schemas/fragment/recommenders/marketing/fdr-marketing-recommendation.generated';
import { FdrProductOfferItemFragment } from 'fdr/schemas/fragment/recommenders/product/fdr-product-offer-recommendation-item.generated';
import { FdrThemeOfferItemFragment } from 'fdr/schemas/fragment/recommenders/theme/fdr-theme-offer-recommendation-item.generated';

type TRecommendationType = TFdrRecommendationUnion['__typename'];

export type TFdrRecommendationUnion =
  | FdrGeoOfferItemFragment
  | FdrMarketingItemFragment
  | FdrThemeOfferItemFragment
  | FdrProductOfferItemFragment
  | FdrBlogArticleRecommendationItemFragment
  | FdrFlightOfferItemFragment
  | FdrFlightItemFragment;

type TRecommendationProps = {
  item: TFdrRecommendationUnion;
  itemsLength?: number;
  index?: number;
  groupName?: string;
  typename?: TRecommendationType;
  recommendationType?: TUnionPageComponents['__typename'];
};
const FdrFlightRecommendationWithPriceWrapper = dynamic<TRecommendationProps>(
  () =>
    import(
      'fdr/components/local/fdr-page-components/recommendations/flight/fdr-flight-recommendation-with-price-wrapper'
    )
);
const FdrFlightRecommendationWithoutPrice = dynamic<TRecommendationProps>(
  () =>
    import(
      'fdr/components/local/fdr-page-components/recommendations/flight/fdr-flight-recommendation-without-price-wrapper'
    )
);

const FdrGeoOfferRecommendation = dynamic<TRecommendationProps>(
  () =>
    import(
      'fdr/components/local/fdr-page-components/recommendations/geo/fdr-geo-offer-recommendation'
    )
);

const FdrMarketingRecommendation = dynamic<TRecommendationProps>(
  () =>
    import(
      'fdr/components/local/fdr-page-components/recommendations/marketing/fdr-marketing-recommendation-wrapper'
    )
);

const FdrThemeOfferRecommendation = dynamic<TRecommendationProps>(
  () =>
    import(
      'fdr/components/local/fdr-page-components/recommendations/theme/fdr-theme-recommendation'
    )
);

const FdrProductRecommendationWrapper = dynamic<TRecommendationProps>(
  () =>
    import(
      'fdr/components/local/fdr-page-components/recommendations/product/fdr-product-recommendation-wrapper'
    )
);

const FdrBlogArticleRecommendation = dynamic<TRecommendationProps>(
  () =>
    import(
      'fdr/components/local/fdr-blog-article/fdr-blog-article-recommendation'
    )
);

const isEmptyRecommendation = pipe(omit([`__typename`]), isEmpty);

const componentsMap = new Map<
  TRecommendationType,
  React.ComponentType<TRecommendationProps>
>([
  [`FdrFlightRecommenderItem`, FdrFlightRecommendationWithoutPrice],
  [`FdrGeoOffer`, FdrGeoOfferRecommendation],
  [`FdrMarketingRecommenderItem`, FdrMarketingRecommendation],
  [`FdrThemeOffer`, FdrThemeOfferRecommendation],
  [`FdrProductOffer`, FdrProductRecommendationWrapper],
  [`FdrRssBlogArticle`, FdrBlogArticleRecommendation],
  [`FdrFlightRecommenderOfferItem`, FdrFlightRecommendationWithPriceWrapper],
]);

export const getFdrRecommendationComponent =
  (
    groupName: string,
    itemsLength: number,
    recommendationType?: TUnionPageComponents['__typename']
  ) =>
  (item: TFdrRecommendationUnion, i: number) =>
    getFdrRecommendationComponentRender(
      groupName,
      itemsLength,
      item,
      i,
      recommendationType
    );

const getFdrRecommendationComponentRender = (
  groupName: string,
  itemsLength: number,
  recommendation: TFdrRecommendationUnion,
  i: number,
  recommendationType?: TUnionPageComponents['__typename']
) => {
  if (isEmptyRecommendation(recommendation)) {
    // eslint-disable-next-line no-console
    console.warn(`Recommendation ${recommendation.__typename} is empty`);
    return null;
  }

  const Component = componentsMap.get(recommendation.__typename);

  return Component ? (
    <Component
      item={recommendation}
      index={i}
      itemsLength={itemsLength}
      groupName={groupName}
      recommendationType={recommendationType}
    />
  ) : null;
};
