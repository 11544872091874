import {
  query2FdrCriteria,
  TFdrMediaMeta,
  TFdrMediaMetaMap,
} from '@hotelplan/fdr.regular.basis.fdr-picture';
import { lCfg, pCfg } from 'fdr/components/util/criterias/base.dmc';
import { FdrImageResizeMode } from '@hotelplan/supergraph-api';

export const fdrRecommendationProductImageMobileImagesSpecs: Array<
  [string, TFdrMediaMeta]
> = [
  [`364w_mobile`, pCfg(364, 147, FdrImageResizeMode.Fill)],
  [`728w_mobile`, pCfg(728, 294, FdrImageResizeMode.Fill)],
];

const fdrRecommendationProductImageDesktopImagesSpecs: Array<
  [string, TFdrMediaMeta]
> = [
  [`585w_desktop`, lCfg(585, 450, FdrImageResizeMode.Fill)],
  [`1170w_desktop`, lCfg(1170, 900, FdrImageResizeMode.Fill)],
];

export const fdrRecommendationProductImageImagesSpecs: TFdrMediaMetaMap =
  new Map([
    ...fdrRecommendationProductImageMobileImagesSpecs,
    ...fdrRecommendationProductImageDesktopImagesSpecs,
  ]);

export const fdrRecommendationProductImageCriteria = query2FdrCriteria(
  fdrRecommendationProductImageImagesSpecs
);
