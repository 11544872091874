import dynamic from 'next/dynamic';
import React, { FC } from 'react';
import { FdrTextBlock } from '@hotelplan/fdr.regular.basis.fdr-text-block';
import { FdrRawText } from '@hotelplan/fdr.regular.fusion.fdr-raw-text';
import { FdrMediaGalleryPageComponents } from 'fdr/components/local/fdr-page-components/media-gallery/fdr-media-gallery-page-components';
import {
  useGetFdrGeoOfferRecommendationComponent,
  useGetFdrThemeOfferRecommendationComponent,
  useGetFdrBlogArticleRecommendationComponent,
  useGetFdrProductOfferRecommendationComponent,
  useGetFdrFlightOfferRecommendationComponent,
  useGetFdrSmartSeerRecommendationComponent,
} from 'fdr/components/local/fdr-recommendation-group/component-request';
import { useGetFdrHomeSmartSeerRecommendationComponent } from 'fdr/components/local/fdr-recommendation-group/component-request/useGetFdrHomeSmartSeerRecommendationComponent';
import { FdrRecommendationGroup } from 'fdr/components/local/fdr-recommendation-group/fdr-recommendation-group';
import { FdrRecommendationGroupWithMedia } from 'fdr/components/local/fdr-recommendation-group/recommendation-with-media/fdr-recommendation-group-with-media';
import { FdrFaqContainer, FdrFaqWrapper } from './fdr-page-components.styles';
import { TUnionPageComponents } from './fdr-page-components.types';
import {
  getFdrBottomPadding,
  getFdrTopPadding,
} from './fdr-page-components.utils';

const FdrNewsletterSignup = dynamic(
  () =>
    import(
      'fdr/components/local/fdr-newsletter/signup-component/fdr-newsletter-signup'
    )
);
const FdrDynamicAccordion = dynamic(
  () =>
    import('fdr/components/local/fdr-dynamic-accordion/fdr-dynamic-accordion')
);
const FdrMarketingTeaser = dynamic(
  () => import('fdr/components/local/fdr-marketing/fdr-marketing-teaser')
);
const FdrImageTextComponent = dynamic(
  () =>
    import(
      'fdr/components/local/fdr-page-components/image-text/fdr-image-text-component'
    )
);
const FdrDestinationClimate = dynamic(() =>
  import('fdr/components/local/fdr-geo/fdr-destination-climate').then(
    module => module.FdrDestinationClimate
  )
);
const FdrGeoChildrenPageComponent = dynamic(() =>
  import(
    'fdr/components/local/fdr-geo/page-components/fdr-geo-children-page-component'
  ).then(module => module.FdrGeoChildrenPageComponent)
);
const FdrGeoGalleryPageComponent = dynamic(() =>
  import(
    'fdr/components/local/fdr-geo/page-components/fdr-geo-gallery-page-component'
  ).then(module => module.FdrGeoGalleryPageComponent)
);
const FdrGeoMapPageComponent = dynamic(() =>
  import(
    'fdr/components/local/fdr-geo/page-components/fdr-geo-map-page-component'
  ).then(module => module.FdrGeoMapPageComponent)
);
const FdrGeoOfferButtonPageComponent = dynamic(() =>
  import(
    'fdr/components/local/fdr-geo/page-components/fdr-geo-offer-button-page-component'
  ).then(module => module.FdrGeoOfferButtonPageComponent)
);
const FdrThemeSrlButton = dynamic(() =>
  import('fdr/components/local/fdr-theme/fdr-theme-srl-button').then(
    module => module.FdrThemeSrlButton
  )
);
const FdrThemeMapAccessBlock = dynamic(() =>
  import('fdr/components/local/fdr-theme/fdr-theme-map-access-block').then(
    module => module.FdrThemeMapAccessBlock
  )
);

interface IFdrPageComponentProps {
  id: string;
  component: TUnionPageComponents;
  nextComponentTypename?: string;
  isHomeSmartSeerRecommender?: boolean;
}

export const FdrPageComponent: FC<IFdrPageComponentProps> = (
  props
): React.ReactElement => {
  const { id, component, nextComponentTypename, isHomeSmartSeerRecommender } =
    props;

  switch (component.__typename) {
    case 'FdrMarkdownText':
    case 'FdrRawText':
      const isRawText = component.__typename === 'FdrRawText';
      const { bottomPadding, topPadding, id: componentId } = component;

      const textBlockBottomPadding = getFdrBottomPadding(bottomPadding);
      const textBlockTopPadding = getFdrTopPadding(topPadding);

      return (
        <>
          {isRawText ? (
            <FdrRawText
              uid={`raw-text-${componentId}`}
              text={component?.text}
              className={`text-block raw-text ${textBlockTopPadding} ${textBlockBottomPadding}`}
            />
          ) : (
            <FdrTextBlock
              id={componentId}
              text={component?.text}
              className={`text-block markdown-text ${textBlockTopPadding} ${textBlockBottomPadding}`}
            />
          )}
        </>
      );

    case 'FdrFaq': {
      const isNextIsFaq = nextComponentTypename === 'FdrFaq';

      return (
        <FdrFaqContainer key={id}>
          <FdrFaqWrapper mb={!isNextIsFaq} {...component} />
        </FdrFaqContainer>
      );
    }
    case 'FdrGeoOfferRecommender':
    case 'FdrThemeOfferRecommender':
    case 'FdrProductOfferRecommender':
    case 'FdrRssBlogArticleRecommender':
    case 'FdrSmartSeerRecommender':
    case 'FdrFlightOfferRecommender':
      const getFetchMoreHook = () => {
        switch (component.__typename) {
          case 'FdrGeoOfferRecommender':
            return useGetFdrGeoOfferRecommendationComponent;
          case 'FdrThemeOfferRecommender':
            return useGetFdrThemeOfferRecommendationComponent;
          case 'FdrProductOfferRecommender':
            return useGetFdrProductOfferRecommendationComponent;
          case 'FdrSmartSeerRecommender':
            return isHomeSmartSeerRecommender
              ? useGetFdrHomeSmartSeerRecommendationComponent
              : useGetFdrSmartSeerRecommendationComponent;
          case 'FdrRssBlogArticleRecommender':
            return useGetFdrBlogArticleRecommendationComponent;
          case 'FdrFlightOfferRecommender':
            return useGetFdrFlightOfferRecommendationComponent;
        }
      };

      return (
        <FdrRecommendationGroup
          id={component.id}
          recommendationType={component.__typename}
          fontColor={component.fgColor}
          groupName={component.title}
          mainText={component.text}
          items={
            (component.__typename === 'FdrFlightOfferRecommender'
              ? component.flightItemsPage.items
              : component.itemsPage?.items) || []
          }
          pagination={
            component.__typename === 'FdrFlightOfferRecommender'
              ? component.flightItemsPage.pagination
              : component.itemsPage?.pagination
          }
          tracking={
            component.__typename === 'FdrSmartSeerRecommender'
              ? {
                  ...component.itemsPage.tracking,
                  placementId: component.itemsPage.placementId,
                  count: component.itemsPage.pagination.total,
                }
              : undefined
          }
          useGet={getFetchMoreHook()}
        />
      );

    case 'FdrMarketingRecommender':
    case 'FdrFlightRecommender':
      return (
        <FdrRecommendationGroupWithMedia
          id={component.id}
          fontColor={component.fgColor}
          groupName={component.title}
          mainText={component.text}
          items={
            component.__typename === 'FdrFlightRecommender'
              ? component.flightRecommenderItems
              : component.items
          }
        />
      );

    case 'FdrNewsletterSignup':
      return <FdrNewsletterSignup newsletterSignupInformation={component} />;
    case 'FdrMarketingTeaser':
      return <FdrMarketingTeaser data={component} />;
    case 'FdrImageText':
      return <FdrImageTextComponent data={component} />;
    case 'FdrAccordionComponent':
      return <FdrDynamicAccordion data={component} />;
    case 'FdrMediaGallery':
      return <FdrMediaGalleryPageComponents component={component} />;

    case 'FdrSrlButton':
      return <FdrThemeSrlButton link={component.webMeta.link} />;
    case 'FdrProductOffersMap':
      return <FdrThemeMapAccessBlock />;

    // geo placeholders
    case 'FdrGeoOfferButtonPlaceholder':
      return <FdrGeoOfferButtonPageComponent />;
    case 'FdrGeoMapPlaceholder':
      return <FdrGeoMapPageComponent title={component?.text} />;
    case 'FdrGeoClimatePlaceholder':
      return <FdrDestinationClimate title={component?.text} />;
    case 'FdrGeoChildrenPlaceholder':
      return <FdrGeoChildrenPageComponent title={component?.text} />;
    case 'FdrGeoGalleryPlaceholder':
      return <FdrGeoGalleryPageComponent title={component?.text} />;
  }

  return null;
};
