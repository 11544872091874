import type * as Types from '@hotelplan/supergraph-api';

import type { DocumentNode } from 'graphql';
import { FdrLinkFragmentDoc } from 'fdr/schemas/fragment/regular/fdr-link.generated';
import { FdrImageFragmentDoc } from 'fdr/schemas/fragment/regular/fdr-image.generated';
import { FdrResizedFragmentDoc } from 'fdr/schemas/fragment/regular/fdr-resized.generated';
import { FdrPriceFragmentDoc } from 'fdr/schemas/fragment/regular/fdr-price.generated';
export type FdrGeoOfferItemFragment = {
  __typename?: 'FdrGeoOffer';
  productCount: number;
  webMeta: {
    __typename?: 'FdrSearchResultWebMeta';
    link: {
      __typename?: 'FdrLink';
      openMethod?: Types.FdrLinkOpenMethod | null;
      legacyUrl?: string | null;
      type: Types.FdrLinkType;
      url: string;
    };
  };
  geo?:
    | {
        __typename?: 'FdrDestination';
        flightTime?: number | null;
        mapZoom: number;
        id: string;
        title: string;
        country: { __typename?: 'FdrCountry'; name: string };
        image?: {
          __typename?: 'FdrImage';
          alt: string;
          description?: string | null;
          copyright?: string | null;
          rawUrl: string;
          desaturated?: boolean | null;
          resized: Array<{
            __typename?: 'FdrResizedImage';
            id: string;
            link: {
              __typename?: 'FdrLink';
              openMethod?: Types.FdrLinkOpenMethod | null;
              legacyUrl?: string | null;
              type: Types.FdrLinkType;
              url: string;
            };
          }>;
        } | null;
        coordinates?: {
          __typename?: 'FdrCoordinates';
          latitude: number;
          longitude: number;
        } | null;
      }
    | {
        __typename?: 'FdrCountry';
        flightTime?: number | null;
        mapZoom: number;
        id: string;
        title: string;
        image?: {
          __typename?: 'FdrImage';
          alt: string;
          description?: string | null;
          copyright?: string | null;
          rawUrl: string;
          desaturated?: boolean | null;
          resized: Array<{
            __typename?: 'FdrResizedImage';
            id: string;
            link: {
              __typename?: 'FdrLink';
              openMethod?: Types.FdrLinkOpenMethod | null;
              legacyUrl?: string | null;
              type: Types.FdrLinkType;
              url: string;
            };
          }>;
        } | null;
        coordinates?: {
          __typename?: 'FdrCoordinates';
          latitude: number;
          longitude: number;
        } | null;
      }
    | {
        __typename?: 'FdrContinent';
        mapZoom: number;
        id: string;
        title: string;
        image?: {
          __typename?: 'FdrImage';
          alt: string;
          description?: string | null;
          copyright?: string | null;
          rawUrl: string;
          desaturated?: boolean | null;
          resized: Array<{
            __typename?: 'FdrResizedImage';
            id: string;
            link: {
              __typename?: 'FdrLink';
              openMethod?: Types.FdrLinkOpenMethod | null;
              legacyUrl?: string | null;
              type: Types.FdrLinkType;
              url: string;
            };
          }>;
        } | null;
        coordinates?: {
          __typename?: 'FdrCoordinates';
          latitude: number;
          longitude: number;
        } | null;
      }
    | {
        __typename?: 'FdrCountryGroup';
        mapZoom: number;
        id: string;
        title: string;
        image?: {
          __typename?: 'FdrImage';
          alt: string;
          description?: string | null;
          copyright?: string | null;
          rawUrl: string;
          desaturated?: boolean | null;
          resized: Array<{
            __typename?: 'FdrResizedImage';
            id: string;
            link: {
              __typename?: 'FdrLink';
              openMethod?: Types.FdrLinkOpenMethod | null;
              legacyUrl?: string | null;
              type: Types.FdrLinkType;
              url: string;
            };
          }>;
        } | null;
        coordinates?: {
          __typename?: 'FdrCoordinates';
          latitude: number;
          longitude: number;
        } | null;
      }
    | {
        __typename?: 'FdrRegion';
        flightTime?: number | null;
        mapZoom: number;
        id: string;
        title: string;
        country: { __typename?: 'FdrCountry'; name: string };
        image?: {
          __typename?: 'FdrImage';
          alt: string;
          description?: string | null;
          copyright?: string | null;
          rawUrl: string;
          desaturated?: boolean | null;
          resized: Array<{
            __typename?: 'FdrResizedImage';
            id: string;
            link: {
              __typename?: 'FdrLink';
              openMethod?: Types.FdrLinkOpenMethod | null;
              legacyUrl?: string | null;
              type: Types.FdrLinkType;
              url: string;
            };
          }>;
        } | null;
        coordinates?: {
          __typename?: 'FdrCoordinates';
          latitude: number;
          longitude: number;
        } | null;
      }
    | {
        __typename?: 'FdrResort';
        flightTime?: number | null;
        mapZoom: number;
        id: string;
        title: string;
        image?: {
          __typename?: 'FdrImage';
          alt: string;
          description?: string | null;
          copyright?: string | null;
          rawUrl: string;
          desaturated?: boolean | null;
          resized: Array<{
            __typename?: 'FdrResizedImage';
            id: string;
            link: {
              __typename?: 'FdrLink';
              openMethod?: Types.FdrLinkOpenMethod | null;
              legacyUrl?: string | null;
              type: Types.FdrLinkType;
              url: string;
            };
          }>;
        } | null;
        coordinates?: {
          __typename?: 'FdrCoordinates';
          latitude: number;
          longitude: number;
        } | null;
      }
    | null;
  topOffer: {
    __typename?: 'FdrProductOffer';
    duration: number;
    averageTemperature?: number | null;
    pricePerPerson: {
      __typename?: 'FdrPrice';
      value: number;
      currency: string;
    };
    rooms: Array<{
      __typename?: 'FdrProductOfferRoom';
      adults: number;
      childrenDobs?: Array<any> | null;
    }>;
    product?:
      | {
          __typename?: 'FdrCruise';
          id: string;
          disrupters?: Array<{
            __typename?: 'FdrDisrupter';
            text: string;
          }> | null;
        }
      | {
          __typename?: 'FdrHotel';
          id: string;
          disrupters?: Array<{
            __typename?: 'FdrDisrupter';
            text: string;
          }> | null;
        }
      | {
          __typename?: 'FdrRoundTrip';
          id: string;
          disrupters?: Array<{
            __typename?: 'FdrDisrupter';
            text: string;
          }> | null;
        }
      | {
          __typename?: 'FdrAdventureTravel';
          id: string;
          disrupters?: Array<{
            __typename?: 'FdrDisrupter';
            text: string;
          }> | null;
        }
      | null;
  };
};

export const FdrGeoOfferItemFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'fdrGeoOfferItem' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FdrGeoOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'webMeta' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'link' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'fdrLink' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'geo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'id' },
                  name: { kind: 'Name', value: 'publicId' },
                },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'title' },
                  name: { kind: 'Name', value: 'name' },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'FdrRegion' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'flightTime' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'country' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'FdrCountry' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'flightTime' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'FdrDestination' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'flightTime' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'country' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'FdrResort' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'flightTime' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'image' },
                  name: { kind: 'Name', value: 'hero' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'fdrImage' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'resized' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'criteria' },
                            value: {
                              kind: 'ListValue',
                              values: [
                                {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: 'id' },
                                      value: {
                                        kind: 'StringValue',
                                        value: '2x_mobile',
                                        block: false,
                                      },
                                    },
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: 'width' },
                                      value: { kind: 'IntValue', value: '728' },
                                    },
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: 'height' },
                                      value: { kind: 'IntValue', value: '294' },
                                    },
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: 'mode' },
                                      value: {
                                        kind: 'EnumValue',
                                        value: 'FILL',
                                      },
                                    },
                                  ],
                                },
                                {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: 'id' },
                                      value: {
                                        kind: 'StringValue',
                                        value: '1x_mobile',
                                        block: false,
                                      },
                                    },
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: 'width' },
                                      value: { kind: 'IntValue', value: '364' },
                                    },
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: 'height' },
                                      value: { kind: 'IntValue', value: '147' },
                                    },
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: 'mode' },
                                      value: {
                                        kind: 'EnumValue',
                                        value: 'FILL',
                                      },
                                    },
                                  ],
                                },
                                {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: 'id' },
                                      value: {
                                        kind: 'StringValue',
                                        value: '1x_desktop',
                                        block: false,
                                      },
                                    },
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: 'width' },
                                      value: { kind: 'IntValue', value: '368' },
                                    },
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: 'height' },
                                      value: { kind: 'IntValue', value: '188' },
                                    },
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: 'mode' },
                                      value: {
                                        kind: 'EnumValue',
                                        value: 'FILL',
                                      },
                                    },
                                  ],
                                },
                              ],
                            },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'fdrResized' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'mapZoom' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'coordinates' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'latitude' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'longitude' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'productCount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'topOffer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pricePerPerson' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'fdrPrice' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'duration' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'rooms' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'adults' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'childrenDobs' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'averageTemperature' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'product' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'FdrAdventureTravel' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              alias: { kind: 'Name', value: 'id' },
                              name: { kind: 'Name', value: 'publicId' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'disrupters' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'text' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'FdrCruise' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              alias: { kind: 'Name', value: 'id' },
                              name: { kind: 'Name', value: 'publicId' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'disrupters' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'text' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'FdrHotel' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              alias: { kind: 'Name', value: 'id' },
                              name: { kind: 'Name', value: 'publicId' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'disrupters' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'text' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'FdrRoundTrip' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              alias: { kind: 'Name', value: 'id' },
                              name: { kind: 'Name', value: 'publicId' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'disrupters' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'text' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...FdrLinkFragmentDoc.definitions,
    ...FdrImageFragmentDoc.definitions,
    ...FdrResizedFragmentDoc.definitions,
    ...FdrPriceFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;
