import type * as Types from '@hotelplan/supergraph-api';

import type { DocumentNode } from 'graphql';
export type FdrGeoOfferButtonPlaceholderFragment = {
  __typename?: 'FdrGeoOfferButtonPlaceholder';
  anchorId?: string | null;
  anchorLabel?: string | null;
  bgColor?: string | null;
  id: string;
  inPageNavigable: boolean;
};

export const FdrGeoOfferButtonPlaceholderFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'fdrGeoOfferButtonPlaceholder' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FdrGeoOfferButtonPlaceholder' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'anchorId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'anchorLabel' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bgColor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'inPageNavigable' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
