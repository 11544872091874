import type * as Types from '@hotelplan/supergraph-api';

import type { DocumentNode } from 'graphql';
import {
  FdrMediaImageFragmentDoc,
  FdrMediaVideoFragmentDoc,
  FdrMediaAudioFragmentDoc,
  FdrMediaPdfFragmentDoc,
  FdrMediaExternalFragmentDoc,
} from 'fdr/schemas/fragment/media/fdr-media.generated';
import { FdrLinkFragmentDoc } from 'fdr/schemas/fragment/regular/fdr-link.generated';
import { FdrFlightFragmentDoc } from 'fdr/schemas/fragment/regular/fdr-flight.generated';
import { FdrPriceFragmentDoc } from 'fdr/schemas/fragment/regular/fdr-price.generated';
export type FdrFlightOfferItemFragment = {
  __typename?: 'FdrFlightRecommenderOfferItem';
  amount: number;
  item?: {
    __typename?: 'FdrFlightOfferRecommenderItem';
    title?: string | null;
    recommenderId: string;
    itemIndex: number;
    media?:
      | { __typename?: 'FdrAudio' }
      | { __typename?: 'FdrExternalMedia' }
      | {
          __typename?: 'FdrImage';
          alt: string;
          description?: string | null;
          copyright?: string | null;
          rawUrl: string;
          desaturated?: boolean | null;
          resized: Array<{
            __typename?: 'FdrResizedImage';
            id: string;
            link: {
              __typename?: 'FdrLink';
              openMethod?: Types.FdrLinkOpenMethod | null;
              legacyUrl?: string | null;
              type: Types.FdrLinkType;
              url: string;
            };
          }>;
        }
      | { __typename?: 'FdrPdf' }
      | { __typename?: 'FdrVideo' }
      | null;
  } | null;
  offer: {
    __typename?: 'FdrFlightOffer';
    id: string;
    webMeta: {
      __typename?: 'FdrFlightSearchResultWebMeta';
      link: {
        __typename?: 'FdrLink';
        openMethod?: Types.FdrLinkOpenMethod | null;
        legacyUrl?: string | null;
        type: Types.FdrLinkType;
        url: string;
      };
    };
    forwardFlight: {
      __typename?: 'FdrFlight';
      duration: number;
      overnight: boolean;
      baggageInfo?: Array<string> | null;
      segments: Array<{
        __typename?: 'FdrFlightSegment';
        flightNumber: string;
        flightClass: Types.FdrFlightClass;
        duration: number;
        arrivalDate: any;
        arrivalTime: any;
        baggageIncluded: boolean;
        baggageInfo?: Array<string> | null;
        departureDate: any;
        departureTime: any;
        airportWaiting?: number | null;
        airline: { __typename?: 'FdrAirline'; iata: string; name: string };
        arrivalAirport: {
          __typename?: 'FdrAirport';
          name: string;
          isGroup: boolean;
          iata: string;
          codes?: Array<string> | null;
          coordinates?: {
            __typename?: 'FdrCoordinates';
            longitude: number;
            latitude: number;
          } | null;
        };
        departureAirport: {
          __typename?: 'FdrAirport';
          name: string;
          isGroup: boolean;
          iata: string;
          codes?: Array<string> | null;
          coordinates?: {
            __typename?: 'FdrCoordinates';
            longitude: number;
            latitude: number;
          } | null;
        };
      }>;
    };
    returnFlight?: {
      __typename?: 'FdrFlight';
      duration: number;
      overnight: boolean;
      baggageInfo?: Array<string> | null;
      segments: Array<{
        __typename?: 'FdrFlightSegment';
        flightNumber: string;
        flightClass: Types.FdrFlightClass;
        duration: number;
        arrivalDate: any;
        arrivalTime: any;
        baggageIncluded: boolean;
        baggageInfo?: Array<string> | null;
        departureDate: any;
        departureTime: any;
        airportWaiting?: number | null;
        airline: { __typename?: 'FdrAirline'; iata: string; name: string };
        arrivalAirport: {
          __typename?: 'FdrAirport';
          name: string;
          isGroup: boolean;
          iata: string;
          codes?: Array<string> | null;
          coordinates?: {
            __typename?: 'FdrCoordinates';
            longitude: number;
            latitude: number;
          } | null;
        };
        departureAirport: {
          __typename?: 'FdrAirport';
          name: string;
          isGroup: boolean;
          iata: string;
          codes?: Array<string> | null;
          coordinates?: {
            __typename?: 'FdrCoordinates';
            longitude: number;
            latitude: number;
          } | null;
        };
      }>;
    } | null;
    perPersonPrice: {
      __typename?: 'FdrPrice';
      value: number;
      currency: string;
    };
  };
};

export const FdrFlightOfferItemFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'fdrFlightOfferItem' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FdrFlightRecommenderOfferItem' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'item' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'recommenderId' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'itemIndex' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'media' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'fdrMediaImage' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'offer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'webMeta' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'link' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'fdrLink' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'forwardFlight' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'fdrFlight' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'returnFlight' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'fdrFlight' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'perPersonPrice' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'fdrPrice' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...FdrMediaImageFragmentDoc.definitions,
    ...FdrLinkFragmentDoc.definitions,
    ...FdrFlightFragmentDoc.definitions,
    ...FdrPriceFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;
