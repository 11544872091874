import type * as Types from '@hotelplan/supergraph-api';

import type { DocumentNode } from 'graphql';
import { FdrFaqFragmentDoc } from 'fdr/schemas/fragment/faq/fdr-faq.generated';
export type FdrAccordionComponentFragment = {
  __typename?: 'FdrAccordionComponent';
  id: string;
  bottomBackgroundColor?: string | null;
  bottomFontColor?: string | null;
  bottomMainText?: string | null;
  bottomMainTitle?: string | null;
  topBackgroundColor?: string | null;
  topFontColor?: string | null;
  topMainText?: string | null;
  topMainTitle?: string | null;
  accordionComponents?: {
    __typename?: 'FdrAccordionComponents';
    components?: Array<
      | { __typename: 'FdrSmartSeerRecommender' }
      | { __typename: 'FdrRssBlogArticleRecommender' }
      | { __typename: 'FdrAccordion' }
      | { __typename: 'FdrAccordionComponent' }
      | { __typename: 'FdrAgencyContactModule' }
      | { __typename: 'FdrAgencyRecommender' }
      | { __typename: 'FdrBenefitsComponent' }
      | { __typename: 'FdrBlogArticleRecommender' }
      | { __typename: 'FdrBrandsComponent' }
      | { __typename: 'FdrContactModule' }
      | { __typename: 'FdrEmployeesComponent' }
      | {
          __typename: 'FdrFaq';
          bgColor?: string | null;
          fgColor?: string | null;
          answer: string;
          question: string;
        }
      | { __typename: 'FdrFlightOfferRecommender' }
      | { __typename: 'FdrFlightRecommender' }
      | { __typename: 'FdrGeoChildrenPlaceholder' }
      | { __typename: 'FdrGeoClimatePlaceholder' }
      | { __typename: 'FdrGeoGalleryPlaceholder' }
      | { __typename: 'FdrGeoLeadTextPlaceholder' }
      | { __typename: 'FdrGeoLongTextPlaceholder' }
      | { __typename: 'FdrGeoMapPlaceholder' }
      | { __typename: 'FdrGeoOfferButtonPlaceholder' }
      | { __typename: 'FdrGeoOfferRecommender' }
      | { __typename: 'FdrGeoRecommender' }
      | { __typename: 'FdrGeoRecommenderPlaceholder' }
      | { __typename: 'FdrHeroMediaGallery' }
      | { __typename: 'FdrImageText' }
      | { __typename: 'FdrLinkList' }
      | { __typename: 'FdrMarkdownText' }
      | { __typename: 'FdrMarketingRecommender' }
      | { __typename: 'FdrMarketingTeaser' }
      | { __typename: 'FdrMediaGallery' }
      | { __typename: 'FdrNewsArticlesRecommender' }
      | { __typename: 'FdrNewsletterSignup' }
      | { __typename: 'FdrProductOfferRecommender' }
      | { __typename: 'FdrProductOffersMap' }
      | { __typename: 'FdrProductRecommender' }
      | { __typename: 'FdrRawText' }
      | { __typename: 'FdrSrlButton' }
      | { __typename: 'FdrThemeGeoRecommender' }
      | { __typename: 'FdrThemeOfferRecommender' }
      | { __typename: 'FdrThemeRecommender' }
      | { __typename: 'FdrUspBoxesComponent' }
    > | null;
  } | null;
};

export const FdrAccordionComponentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'fdrAccordionComponent' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FdrAccordionComponent' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bottomBackgroundColor' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'bottomFontColor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bottomMainText' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bottomMainTitle' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'topBackgroundColor' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'topFontColor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'topMainText' } },
          { kind: 'Field', name: { kind: 'Name', value: 'topMainTitle' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'accordionComponents' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'pagination' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'page' },
                            value: { kind: 'IntValue', value: '0' },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'perPage' },
                            value: { kind: 'IntValue', value: '100' },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'components' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: '__typename' },
                      },
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'fdrFaq' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...FdrFaqFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;
