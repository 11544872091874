import React, { useMemo } from 'react';
import { EPageType } from '@hotelplan/fdr.lib.page.available-pages';
import { usePageType } from '@hotelplan/libs.context.page-type';
import { fdrRenderComponent } from 'fdr/components/local/fdr-page-components/fdr-render-component.utils';
import { FdrPageComponentsContextProvider } from './fdr-page-components-context';
import { TUnionPageComponents } from './fdr-page-components.types';

interface IFdrComponentsBasedPageComponentsProps {
  components?: TUnionPageComponents[];
  className?: string;
}

export function FdrComponentsBasedPageComponents({
  components,
  className,
}: IFdrComponentsBasedPageComponentsProps) {
  const pageType = usePageType<EPageType>();

  const pageComponents = useMemo(() => {
    let container = null;
    const renderedDynamicComponents = [];
    const renderedDynamicComponentsWithoutContainer = [];

    const firstComponentWithAnchor = components.find(component => {
      return 'anchorId' in component && component?.anchorId;
    });

    let currentAnchor =
      firstComponentWithAnchor && 'anchorId' in firstComponentWithAnchor
        ? firstComponentWithAnchor.anchorId
        : null;

    components.forEach((component, index) => {
      if ('anchorId' in component && component?.anchorId) {
        if (container) {
          renderedDynamicComponents.push(
            <div id={currentAnchor} key={component?.anchorId}>
              {container}
            </div>
          );
          currentAnchor = component?.anchorId;
        }
        container = [];
      }

      if (!container) {
        renderedDynamicComponentsWithoutContainer.push(
          fdrRenderComponent(component, pageType, index)
        );
      }

      container?.push(fdrRenderComponent(component, pageType, index));

      if (index === components.length - 1 && container) {
        renderedDynamicComponents.push(
          <div id={currentAnchor} key={index}>
            {container}
          </div>
        );
      }
    });

    return [
      ...renderedDynamicComponentsWithoutContainer,
      ...renderedDynamicComponents,
    ];
  }, [components, pageType]);

  return (
    <section data-id="dynamic-components" className={className}>
      <FdrPageComponentsContextProvider value={{ count: components.length }}>
        {pageComponents}
      </FdrPageComponentsContextProvider>
    </section>
  );
}
