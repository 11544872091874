import React, { createContext, useContext } from 'react';

interface IDynamicComponentsContextValue {
  count: number | undefined;
}

interface IDynamicComponentsProviderProps extends React.PropsWithChildren<{}> {
  value: IDynamicComponentsContextValue;
}

const FdrPageComponentsContext = createContext<IDynamicComponentsContextValue>({
  count: undefined,
});

export const FdrPageComponentsContextProvider: React.FC<IDynamicComponentsProviderProps> =
  ({ value, children }) => {
    return (
      <FdrPageComponentsContext.Provider value={value}>
        {children}
      </FdrPageComponentsContext.Provider>
    );
  };

export function useFdrPageComponentsContext() {
  return useContext(FdrPageComponentsContext);
}
