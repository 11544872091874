import type { ApolloQueryResult } from '@apollo/client/core';
import { TQueryResultWithPatchedFetch } from 'fdr/components/local/fdr-recommendation-group/component-request/component-request.types';
import {
  useFdrFlightOfferRecommendationComponentQuery,
  FdrFlightOfferRecommendationComponentQuery,
} from 'fdr/schemas/query/component/fdr-flight-offer-recommendation-component.generated';

export function useGetFdrFlightOfferRecommendationComponent(
  id: string
): TQueryResultWithPatchedFetch {
  const result = useFdrFlightOfferRecommendationComponentQuery({
    variables: {
      input: { ids: [id] },
      flightPage: 0,
    },
    skip: true,
  });

  return {
    ...result,
    fetchMore(
      page: number
    ): Promise<ApolloQueryResult<FdrFlightOfferRecommendationComponentQuery>> {
      return result.fetchMore({
        variables: {
          input: { ids: [id] },
          flightPage: page,
        },
      });
    },
  };
}
