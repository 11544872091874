import type * as Types from '@hotelplan/supergraph-api';

import type { DocumentNode } from 'graphql';
import { FdrSmartSeerRecommendationFragmentDoc } from 'fdr/schemas/fragment/recommenders/smart-seer/fdr-smart-seer-recommendation.generated';
import type * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@hotelplan/fdr.lib.apollo.query';
const defaultOptions = {} as const;
export type FdrHomeSmartSeerRecommenderQueryVariables = Types.Exact<{
  smartSeerUserId: Types.Scalars['String'];
  smartSeerPage?: Types.Scalars['Int'];
  currentUrl: Types.Scalars['String'];
  gss?: Types.InputMaybe<Types.FdrGlobalSearchStateCriteria>;
  productImageCriteria: Array<Types.FdrImageCriteria> | Types.FdrImageCriteria;
  withCO2Emission: Types.Scalars['Boolean'];
}>;

export type FdrHomeSmartSeerRecommenderQuery = {
  __typename?: 'Query';
  fdrHomePage?: {
    __typename?: 'FdrHomePage';
    smartSeerRecommender?: {
      __typename: 'FdrSmartSeerRecommender';
      id: string;
      title?: string | null;
      text?: string | null;
      bgColor?: string | null;
      fgColor?: string | null;
      inPageNavigable: boolean;
      bgImage?: {
        __typename?: 'FdrImage';
        alt: string;
        description?: string | null;
        copyright?: string | null;
        rawUrl: string;
        desaturated?: boolean | null;
        resized: Array<{
          __typename?: 'FdrResizedImage';
          id: string;
          link: {
            __typename?: 'FdrLink';
            openMethod?: Types.FdrLinkOpenMethod | null;
            legacyUrl?: string | null;
            type: Types.FdrLinkType;
            url: string;
          };
        }>;
      } | null;
      itemsPage: {
        __typename?: 'FdrSmartSeerRecommenderSearchResult';
        placementId: string;
        pagination: {
          __typename?: 'FdrPagination';
          total: number;
          page: number;
          perPage: number;
        };
        items?: Array<{
          __typename?: 'FdrProductOffer';
          id: string;
          duration: number;
          hotelToAirportDistance?: number | null;
          transferIncluded: boolean;
          provider?: string | null;
          productCode?: string | null;
          travelType: Types.FdrProductTravelType;
          departureDate: any;
          returnDate: any;
          isInWishlist?: boolean | null;
          pricePerPerson: {
            __typename?: 'FdrPrice';
            value: number;
            currency: string;
          };
          rooms: Array<{
            __typename?: 'FdrProductOfferRoom';
            adults: number;
            childrenDobs?: Array<any> | null;
            mealDescription: string;
            roomDescription: string;
            bookingCode: string;
            mealType: string;
            roomType: string;
          }>;
          transferPrice?: {
            __typename?: 'FdrPrice';
            value: number;
            currency: string;
          } | null;
          webMeta: {
            __typename?: 'FdrSearchResultWebMeta';
            link: {
              __typename?: 'FdrLink';
              openMethod?: Types.FdrLinkOpenMethod | null;
              legacyUrl?: string | null;
              type: Types.FdrLinkType;
              url: string;
            };
          };
          co2Emission?: {
            __typename?: 'FdrCO2Emission';
            flightEmissionKgCO2e?: number | null;
            hotelEmissionKgCO2e?: number | null;
          } | null;
          product?:
            | {
                __typename: 'FdrCruise';
                giataCode?: number | null;
                name: string;
                fromTo?: string | null;
                mode?: string | null;
                id: string;
                hubData?: {
                  __typename?: 'FdrHubProductData';
                  taReviews: number;
                  taRating: number;
                } | null;
                images?: Array<{
                  __typename?: 'FdrImage';
                  alt: string;
                  description?: string | null;
                  copyright?: string | null;
                  rawUrl: string;
                  desaturated?: boolean | null;
                  resized: Array<{
                    __typename?: 'FdrResizedImage';
                    id: string;
                    link: {
                      __typename?: 'FdrLink';
                      openMethod?: Types.FdrLinkOpenMethod | null;
                      legacyUrl?: string | null;
                      type: Types.FdrLinkType;
                      url: string;
                    };
                  }>;
                }> | null;
                imagesPaginated: {
                  __typename: 'FdrImagesResponse';
                  images?: Array<{
                    __typename?: 'FdrImage';
                    alt: string;
                    description?: string | null;
                    copyright?: string | null;
                    rawUrl: string;
                    desaturated?: boolean | null;
                    resized: Array<{
                      __typename?: 'FdrResizedImage';
                      id: string;
                      link: {
                        __typename?: 'FdrLink';
                        openMethod?: Types.FdrLinkOpenMethod | null;
                        legacyUrl?: string | null;
                        type: Types.FdrLinkType;
                        url: string;
                      };
                    }>;
                  }> | null;
                  pagination: {
                    __typename?: 'FdrPagination';
                    total: number;
                    page: number;
                    perPage: number;
                  };
                };
                disrupters?: Array<{
                  __typename?: 'FdrDisrupter';
                  text: string;
                }> | null;
                featureGroups?: Array<{
                  __typename?: 'FdrProductFeatureGroup';
                  id: string;
                  name: string;
                  features: Array<{
                    __typename?: 'FdrProductFeature';
                    name: string;
                    id: string;
                    enabled: boolean;
                  }>;
                }> | null;
                destination: {
                  __typename?: 'FdrDestination';
                  name: string;
                  id: string;
                  country: {
                    __typename?: 'FdrCountry';
                    name: string;
                    id: string;
                  };
                };
              }
            | {
                __typename: 'FdrHotel';
                giataCode?: number | null;
                name: string;
                rating: number;
                id: string;
                flags?: {
                  __typename?: 'FdrHotelFlags';
                  typeFlags?: {
                    __typename?: 'FdrHotelTypeFlags';
                    apartment: boolean;
                  } | null;
                } | null;
                tripAdvisorReviews?: {
                  __typename?: 'FdrTripAdvisorReviewsResponse';
                  totalRating?: number | null;
                  numReviews?: number | null;
                } | null;
                disrupters?: Array<{
                  __typename?: 'FdrDisrupter';
                  text: string;
                }> | null;
                image?: {
                  __typename?: 'FdrImage';
                  alt: string;
                  description?: string | null;
                  copyright?: string | null;
                  rawUrl: string;
                  desaturated?: boolean | null;
                  resized: Array<{
                    __typename?: 'FdrResizedImage';
                    id: string;
                    link: {
                      __typename?: 'FdrLink';
                      openMethod?: Types.FdrLinkOpenMethod | null;
                      legacyUrl?: string | null;
                      type: Types.FdrLinkType;
                      url: string;
                    };
                  }>;
                } | null;
                imagesPaginated: {
                  __typename: 'FdrImagesResponse';
                  images?: Array<{
                    __typename?: 'FdrImage';
                    alt: string;
                    description?: string | null;
                    copyright?: string | null;
                    rawUrl: string;
                    desaturated?: boolean | null;
                    resized: Array<{
                      __typename?: 'FdrResizedImage';
                      id: string;
                      link: {
                        __typename?: 'FdrLink';
                        openMethod?: Types.FdrLinkOpenMethod | null;
                        legacyUrl?: string | null;
                        type: Types.FdrLinkType;
                        url: string;
                      };
                    }>;
                  }> | null;
                  pagination: {
                    __typename?: 'FdrPagination';
                    total: number;
                    page: number;
                    perPage: number;
                  };
                };
                featureGroups?: Array<{
                  __typename?: 'FdrProductFeatureGroup';
                  id: string;
                  name: string;
                  features: Array<{
                    __typename?: 'FdrProductFeature';
                    name: string;
                    id: string;
                    enabled: boolean;
                  }>;
                }> | null;
                resort: {
                  __typename?: 'FdrResort';
                  name: string;
                  id: string;
                  destination: {
                    __typename?: 'FdrDestination';
                    name: string;
                    id: string;
                    country: {
                      __typename?: 'FdrCountry';
                      name: string;
                      id: string;
                    };
                  };
                };
              }
            | {
                __typename: 'FdrRoundTrip';
                giataCode?: number | null;
                name: string;
                fromTo?: string | null;
                mode?: string | null;
                id: string;
                hubData?: {
                  __typename?: 'FdrHubProductData';
                  taReviews: number;
                  taRating: number;
                } | null;
                image?: {
                  __typename?: 'FdrImage';
                  alt: string;
                  description?: string | null;
                  copyright?: string | null;
                  rawUrl: string;
                  desaturated?: boolean | null;
                  resized: Array<{
                    __typename?: 'FdrResizedImage';
                    id: string;
                    link: {
                      __typename?: 'FdrLink';
                      openMethod?: Types.FdrLinkOpenMethod | null;
                      legacyUrl?: string | null;
                      type: Types.FdrLinkType;
                      url: string;
                    };
                  }>;
                } | null;
                imagesPaginated: {
                  __typename: 'FdrImagesResponse';
                  images?: Array<{
                    __typename?: 'FdrImage';
                    alt: string;
                    description?: string | null;
                    copyright?: string | null;
                    rawUrl: string;
                    desaturated?: boolean | null;
                    resized: Array<{
                      __typename?: 'FdrResizedImage';
                      id: string;
                      link: {
                        __typename?: 'FdrLink';
                        openMethod?: Types.FdrLinkOpenMethod | null;
                        legacyUrl?: string | null;
                        type: Types.FdrLinkType;
                        url: string;
                      };
                    }>;
                  }> | null;
                  pagination: {
                    __typename?: 'FdrPagination';
                    total: number;
                    page: number;
                    perPage: number;
                  };
                };
                disrupters?: Array<{
                  __typename?: 'FdrDisrupter';
                  text: string;
                }> | null;
                featureGroups?: Array<{
                  __typename?: 'FdrProductFeatureGroup';
                  id: string;
                  name: string;
                  features: Array<{
                    __typename?: 'FdrProductFeature';
                    name: string;
                    id: string;
                    enabled: boolean;
                  }>;
                }> | null;
                destination: {
                  __typename?: 'FdrDestination';
                  name: string;
                  id: string;
                  country: {
                    __typename?: 'FdrCountry';
                    name: string;
                    id: string;
                  };
                };
              }
            | { __typename: 'FdrAdventureTravel' }
            | null;
        }> | null;
        tracking: {
          __typename?: 'FdrSmartSeerRecommenderTracking';
          giataCodes?: Array<string> | null;
          modelId?: string | null;
          pToken?: string | null;
          status: Types.FdrSmartSeerRecommenderStatus;
          strategy?: string | null;
        };
      };
    } | null;
  } | null;
};

export const FdrHomeSmartSeerRecommenderDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'FdrHomeSmartSeerRecommender' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'smartSeerUserId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'smartSeerPage' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
          defaultValue: { kind: 'IntValue', value: '0' },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'currentUrl' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'gss' } },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'FdrGlobalSearchStateCriteria' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'productImageCriteria' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'FdrImageCriteria' },
                },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'withCO2Emission' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'Boolean' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fdrHomePage' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'smartSeerRecommender' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: '__typename' },
                      },
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'fdrSmartSeerRecommendation',
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...FdrSmartSeerRecommendationFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;

/**
 * __useFdrHomeSmartSeerRecommenderQuery__
 *
 * To run a query within a React component, call `useFdrHomeSmartSeerRecommenderQuery` and pass it any options that fit your needs.
 * When your component renders, `useFdrHomeSmartSeerRecommenderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFdrHomeSmartSeerRecommenderQuery({
 *   variables: {
 *      smartSeerUserId: // value for 'smartSeerUserId'
 *      smartSeerPage: // value for 'smartSeerPage'
 *      currentUrl: // value for 'currentUrl'
 *      gss: // value for 'gss'
 *      productImageCriteria: // value for 'productImageCriteria'
 *      withCO2Emission: // value for 'withCO2Emission'
 *   },
 * });
 */
export function useFdrHomeSmartSeerRecommenderQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    FdrHomeSmartSeerRecommenderQuery,
    FdrHomeSmartSeerRecommenderQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    FdrHomeSmartSeerRecommenderQuery,
    FdrHomeSmartSeerRecommenderQueryVariables
  >(FdrHomeSmartSeerRecommenderDocument, options);
}
export function useFdrHomeSmartSeerRecommenderLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    FdrHomeSmartSeerRecommenderQuery,
    FdrHomeSmartSeerRecommenderQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    FdrHomeSmartSeerRecommenderQuery,
    FdrHomeSmartSeerRecommenderQueryVariables
  >(FdrHomeSmartSeerRecommenderDocument, options);
}
export type FdrHomeSmartSeerRecommenderQueryHookResult = ReturnType<
  typeof useFdrHomeSmartSeerRecommenderQuery
>;
export type FdrHomeSmartSeerRecommenderLazyQueryHookResult = ReturnType<
  typeof useFdrHomeSmartSeerRecommenderLazyQuery
>;
export type FdrHomeSmartSeerRecommenderQueryResult = Apollo.QueryResult<
  FdrHomeSmartSeerRecommenderQuery,
  FdrHomeSmartSeerRecommenderQueryVariables
>;
