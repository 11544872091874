import styled from 'styled-components';
import { E_BUTTON_TYPE } from '@hotelplan/style.hotelplan-style';
import { sx2CssTheme, sx2CssThemeFn } from '@hotelplan/util.theme.sxc';
import { FdrRecommendationItemSkeleton } from './fdr-recommendation-item.skeleton';
import { FdrRecommendationListSkeleton } from './fdr-recommendation-list.skeleton';
import { BsButton } from '@hotelplan/core.basis.bs-button';

const recommendationsItemCss = sx2CssThemeFn({
  flexShrink: ['0', null],
  '&:last-child': {
    pr: [4, '0px'],
  },
  'a:hover': {
    cursor: 'pointer',
  },
});

export const FdrRecommendationWrap = styled.section<{ full?: boolean }>(
  ({ full, theme: { FONT_SIZE } }) =>
    sx2CssThemeFn({
      display: ['flex', `grid`],
      gridTemplateColumns: [null, full ? null : `repeat(3, 1fr)`],
      gap: [3, '45px 30px'],
      gridAutoFlow: ['column', 'row'],
      overflowX: ['auto', 'initial'],
      mr: [-4, '0px'],
      mt: [3, '35px'],
      '&&& .card-title': FONT_SIZE.S,
      '&&& .card-subtitle': FONT_SIZE.LARGE,
    })
);

export const FdrWrapFixMobile = styled.div(
  sx2CssThemeFn({
    pl: 1,
  })
);

const recommendationSkeletonStyles = sx2CssThemeFn({
  pl: [null, '15px'],
  width: '100%',
  flexShrink: ['0', null],
});

const showMoreLinkIconStyles = ({ theme }) =>
  sx2CssTheme(theme, {
    verticalAlign: 'middle',
  });

const loadMoreButtonStyles = ({ theme }) =>
  sx2CssThemeFn({
    pt: [4, '37px'],
    gap: 2,
    ...theme.FONT_SIZE.LINK_LARGE,
    '.icon': {
      width: '16px',
      height: '16px',
    },
  });

export const FdrItemWrap = styled.div<{
  single: boolean;
  mobile: boolean;
}>(
  recommendationsItemCss,
  ({ single, mobile }) =>
    ({ theme: { media } }) =>
      mobile
        ? {
            maxWidth: single ? '100%' : '290px',
            width: '100%',
            [media.mobile]: { maxWidth: single ? '100%' : '90%' },
            gridColumn: single ? 'span 3' : null,
          }
        : { gridColumn: single ? 'span 3' : null }
);

export const FdrItemSkeleton = styled(FdrRecommendationItemSkeleton).attrs({
  uniqueKey: 'recommendationItemSkeleton',
})({ ...recommendationSkeletonStyles, maxWidth: '290px' });

export const FdrListSkeleton = styled(FdrRecommendationListSkeleton)({
  width: '100%',
});

export const FdrMoreLessButtons = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
});

export const FdrShowMoreButton = styled(BsButton).attrs({
  variant: E_BUTTON_TYPE.LINK_BUTTON,
  'data-id': `load-more-btn-id`,
})(loadMoreButtonStyles, sx2CssThemeFn({ '.icon': showMoreLinkIconStyles }));

export const FdrShowLessButton = styled(BsButton).attrs({
  variant: E_BUTTON_TYPE.LINK_BUTTON,
  'data-id': `load-less-btn-id`,
})(loadMoreButtonStyles, {
  '.icon': { ...showMoreLinkIconStyles, transform: 'rotate(180deg)' },
});
