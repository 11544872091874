import type * as Types from '@hotelplan/supergraph-api';

import type { DocumentNode } from 'graphql';
import { FdrBgImageFragmentDoc } from 'fdr/schemas/fragment/regular/fdr-bg-image.generated';
import { FdrPaginationFragmentDoc } from 'fdr/schemas/fragment/regular/fdr-pagination.generated';
import { FdrFlightOfferItemFragmentDoc } from './fdr-flight-offer-recommendation-item.generated';
export type FdrFlightOfferRecommendationFragment = {
  __typename?: 'FdrFlightOfferRecommender';
  id: string;
  title?: string | null;
  text?: string | null;
  bgColor?: string | null;
  fgColor?: string | null;
  bgImage?: {
    __typename?: 'FdrImage';
    alt: string;
    description?: string | null;
    copyright?: string | null;
    rawUrl: string;
    desaturated?: boolean | null;
    resized: Array<{
      __typename?: 'FdrResizedImage';
      id: string;
      link: {
        __typename?: 'FdrLink';
        openMethod?: Types.FdrLinkOpenMethod | null;
        legacyUrl?: string | null;
        type: Types.FdrLinkType;
        url: string;
      };
    }>;
  } | null;
  flightItemsPage: {
    __typename?: 'FdrFlightOfferRecommenderSearchResult';
    pagination: {
      __typename?: 'FdrPagination';
      total: number;
      page: number;
      perPage: number;
    };
    items?: Array<{
      __typename?: 'FdrFlightRecommenderOfferItem';
      amount: number;
      item?: {
        __typename?: 'FdrFlightOfferRecommenderItem';
        title?: string | null;
        recommenderId: string;
        itemIndex: number;
        media?:
          | { __typename?: 'FdrAudio' }
          | { __typename?: 'FdrExternalMedia' }
          | {
              __typename?: 'FdrImage';
              alt: string;
              description?: string | null;
              copyright?: string | null;
              rawUrl: string;
              desaturated?: boolean | null;
              resized: Array<{
                __typename?: 'FdrResizedImage';
                id: string;
                link: {
                  __typename?: 'FdrLink';
                  openMethod?: Types.FdrLinkOpenMethod | null;
                  legacyUrl?: string | null;
                  type: Types.FdrLinkType;
                  url: string;
                };
              }>;
            }
          | { __typename?: 'FdrPdf' }
          | { __typename?: 'FdrVideo' }
          | null;
      } | null;
      offer: {
        __typename?: 'FdrFlightOffer';
        id: string;
        webMeta: {
          __typename?: 'FdrFlightSearchResultWebMeta';
          link: {
            __typename?: 'FdrLink';
            openMethod?: Types.FdrLinkOpenMethod | null;
            legacyUrl?: string | null;
            type: Types.FdrLinkType;
            url: string;
          };
        };
        forwardFlight: {
          __typename?: 'FdrFlight';
          duration: number;
          overnight: boolean;
          baggageInfo?: Array<string> | null;
          segments: Array<{
            __typename?: 'FdrFlightSegment';
            flightNumber: string;
            flightClass: Types.FdrFlightClass;
            duration: number;
            arrivalDate: any;
            arrivalTime: any;
            baggageIncluded: boolean;
            baggageInfo?: Array<string> | null;
            departureDate: any;
            departureTime: any;
            airportWaiting?: number | null;
            airline: { __typename?: 'FdrAirline'; iata: string; name: string };
            arrivalAirport: {
              __typename?: 'FdrAirport';
              name: string;
              isGroup: boolean;
              iata: string;
              codes?: Array<string> | null;
              coordinates?: {
                __typename?: 'FdrCoordinates';
                longitude: number;
                latitude: number;
              } | null;
            };
            departureAirport: {
              __typename?: 'FdrAirport';
              name: string;
              isGroup: boolean;
              iata: string;
              codes?: Array<string> | null;
              coordinates?: {
                __typename?: 'FdrCoordinates';
                longitude: number;
                latitude: number;
              } | null;
            };
          }>;
        };
        returnFlight?: {
          __typename?: 'FdrFlight';
          duration: number;
          overnight: boolean;
          baggageInfo?: Array<string> | null;
          segments: Array<{
            __typename?: 'FdrFlightSegment';
            flightNumber: string;
            flightClass: Types.FdrFlightClass;
            duration: number;
            arrivalDate: any;
            arrivalTime: any;
            baggageIncluded: boolean;
            baggageInfo?: Array<string> | null;
            departureDate: any;
            departureTime: any;
            airportWaiting?: number | null;
            airline: { __typename?: 'FdrAirline'; iata: string; name: string };
            arrivalAirport: {
              __typename?: 'FdrAirport';
              name: string;
              isGroup: boolean;
              iata: string;
              codes?: Array<string> | null;
              coordinates?: {
                __typename?: 'FdrCoordinates';
                longitude: number;
                latitude: number;
              } | null;
            };
            departureAirport: {
              __typename?: 'FdrAirport';
              name: string;
              isGroup: boolean;
              iata: string;
              codes?: Array<string> | null;
              coordinates?: {
                __typename?: 'FdrCoordinates';
                longitude: number;
                latitude: number;
              } | null;
            };
          }>;
        } | null;
        perPersonPrice: {
          __typename?: 'FdrPrice';
          value: number;
          currency: string;
        };
      };
    } | null> | null;
  };
};

export const FdrFlightOfferRecommendationFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'fdrFlightOfferRecommendation' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FdrFlightOfferRecommender' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bgColor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fgColor' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bgImage' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'fdrBgImage' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'flightItemsPage' },
            name: { kind: 'Name', value: 'flightOfferRecommenderSearchResult' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'pagination' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'page' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'flightPage' },
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'perPage' },
                            value: { kind: 'IntValue', value: '3' },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pagination' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'fdrPagination' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'items' },
                  name: { kind: 'Name', value: 'flightOffers' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'fdrFlightOfferItem' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...FdrBgImageFragmentDoc.definitions,
    ...FdrPaginationFragmentDoc.definitions,
    ...FdrFlightOfferItemFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;
