import type { ApolloQueryResult } from '@apollo/client/core';
import { TQueryResultWithPatchedFetch } from 'fdr/components/local/fdr-recommendation-group/component-request/component-request.types';
import {
  useFdrBlogArticleRecommendationComponentQuery,
  FdrBlogArticleRecommendationComponentQuery,
} from 'fdr/schemas/query/component/fdr-blog-article-recommendation-component.generated';

export function useGetFdrBlogArticleRecommendationComponent(
  id: string
): TQueryResultWithPatchedFetch {
  const result = useFdrBlogArticleRecommendationComponentQuery({
    variables: {
      input: { ids: [id] },
      blogArticlePage: 0,
    },
    skip: true,
  });

  return {
    ...result,
    fetchMore(
      page: number
    ): Promise<ApolloQueryResult<FdrBlogArticleRecommendationComponentQuery>> {
      return result.fetchMore({
        variables: {
          input: { ids: [id] },
          blogArticlePage: page,
        },
      });
    },
  };
}
