import type * as Types from '@hotelplan/supergraph-api';

import type { DocumentNode } from 'graphql';
import { FdrPriceFragmentDoc } from 'fdr/schemas/fragment/regular/fdr-price.generated';
import { FdrOfferRoomFragmentDoc } from 'fdr/schemas/fragment/room/fdr-room.generated';
import { FdrLinkFragmentDoc } from 'fdr/schemas/fragment/regular/fdr-link.generated';
import { FdrCo2EmissionFragmentDoc } from 'fdr/schemas/fragment/co2-emission/fdr-co2-emission.generated';
import { FdrProductRecommenderHotelFragmentDoc } from './fdr-product-offer-recommendation-item-hotel.generated';
import { FdrProductRecommenderRoundTripFragmentDoc } from './fdr-product-offer-recommendation-item-round-trip.generated';
import { FdrProductRecommenderCruiseFragmentDoc } from './fdr-product-offer-recommendation-item-cruise.generated';
export type FdrProductOfferItemFragment = {
  __typename?: 'FdrProductOffer';
  id: string;
  duration: number;
  hotelToAirportDistance?: number | null;
  transferIncluded: boolean;
  provider?: string | null;
  productCode?: string | null;
  travelType: Types.FdrProductTravelType;
  departureDate: any;
  returnDate: any;
  isInWishlist?: boolean | null;
  pricePerPerson: { __typename?: 'FdrPrice'; value: number; currency: string };
  rooms: Array<{
    __typename?: 'FdrProductOfferRoom';
    adults: number;
    childrenDobs?: Array<any> | null;
    mealDescription: string;
    roomDescription: string;
    bookingCode: string;
    mealType: string;
    roomType: string;
  }>;
  transferPrice?: {
    __typename?: 'FdrPrice';
    value: number;
    currency: string;
  } | null;
  webMeta: {
    __typename?: 'FdrSearchResultWebMeta';
    link: {
      __typename?: 'FdrLink';
      openMethod?: Types.FdrLinkOpenMethod | null;
      legacyUrl?: string | null;
      type: Types.FdrLinkType;
      url: string;
    };
  };
  co2Emission?: {
    __typename?: 'FdrCO2Emission';
    flightEmissionKgCO2e?: number | null;
    hotelEmissionKgCO2e?: number | null;
  } | null;
  product?:
    | {
        __typename: 'FdrCruise';
        giataCode?: number | null;
        name: string;
        fromTo?: string | null;
        mode?: string | null;
        id: string;
        hubData?: {
          __typename?: 'FdrHubProductData';
          taReviews: number;
          taRating: number;
        } | null;
        images?: Array<{
          __typename?: 'FdrImage';
          alt: string;
          description?: string | null;
          copyright?: string | null;
          rawUrl: string;
          desaturated?: boolean | null;
          resized: Array<{
            __typename?: 'FdrResizedImage';
            id: string;
            link: {
              __typename?: 'FdrLink';
              openMethod?: Types.FdrLinkOpenMethod | null;
              legacyUrl?: string | null;
              type: Types.FdrLinkType;
              url: string;
            };
          }>;
        }> | null;
        imagesPaginated: {
          __typename: 'FdrImagesResponse';
          images?: Array<{
            __typename?: 'FdrImage';
            alt: string;
            description?: string | null;
            copyright?: string | null;
            rawUrl: string;
            desaturated?: boolean | null;
            resized: Array<{
              __typename?: 'FdrResizedImage';
              id: string;
              link: {
                __typename?: 'FdrLink';
                openMethod?: Types.FdrLinkOpenMethod | null;
                legacyUrl?: string | null;
                type: Types.FdrLinkType;
                url: string;
              };
            }>;
          }> | null;
          pagination: {
            __typename?: 'FdrPagination';
            total: number;
            page: number;
            perPage: number;
          };
        };
        disrupters?: Array<{
          __typename?: 'FdrDisrupter';
          text: string;
        }> | null;
        featureGroups?: Array<{
          __typename?: 'FdrProductFeatureGroup';
          id: string;
          name: string;
          features: Array<{
            __typename?: 'FdrProductFeature';
            name: string;
            id: string;
            enabled: boolean;
          }>;
        }> | null;
        destination: {
          __typename?: 'FdrDestination';
          name: string;
          id: string;
          country: { __typename?: 'FdrCountry'; name: string; id: string };
        };
      }
    | {
        __typename: 'FdrHotel';
        giataCode?: number | null;
        name: string;
        rating: number;
        id: string;
        flags?: {
          __typename?: 'FdrHotelFlags';
          typeFlags?: {
            __typename?: 'FdrHotelTypeFlags';
            apartment: boolean;
          } | null;
        } | null;
        tripAdvisorReviews?: {
          __typename?: 'FdrTripAdvisorReviewsResponse';
          totalRating?: number | null;
          numReviews?: number | null;
        } | null;
        disrupters?: Array<{
          __typename?: 'FdrDisrupter';
          text: string;
        }> | null;
        image?: {
          __typename?: 'FdrImage';
          alt: string;
          description?: string | null;
          copyright?: string | null;
          rawUrl: string;
          desaturated?: boolean | null;
          resized: Array<{
            __typename?: 'FdrResizedImage';
            id: string;
            link: {
              __typename?: 'FdrLink';
              openMethod?: Types.FdrLinkOpenMethod | null;
              legacyUrl?: string | null;
              type: Types.FdrLinkType;
              url: string;
            };
          }>;
        } | null;
        imagesPaginated: {
          __typename: 'FdrImagesResponse';
          images?: Array<{
            __typename?: 'FdrImage';
            alt: string;
            description?: string | null;
            copyright?: string | null;
            rawUrl: string;
            desaturated?: boolean | null;
            resized: Array<{
              __typename?: 'FdrResizedImage';
              id: string;
              link: {
                __typename?: 'FdrLink';
                openMethod?: Types.FdrLinkOpenMethod | null;
                legacyUrl?: string | null;
                type: Types.FdrLinkType;
                url: string;
              };
            }>;
          }> | null;
          pagination: {
            __typename?: 'FdrPagination';
            total: number;
            page: number;
            perPage: number;
          };
        };
        featureGroups?: Array<{
          __typename?: 'FdrProductFeatureGroup';
          id: string;
          name: string;
          features: Array<{
            __typename?: 'FdrProductFeature';
            name: string;
            id: string;
            enabled: boolean;
          }>;
        }> | null;
        resort: {
          __typename?: 'FdrResort';
          name: string;
          id: string;
          destination: {
            __typename?: 'FdrDestination';
            name: string;
            id: string;
            country: { __typename?: 'FdrCountry'; name: string; id: string };
          };
        };
      }
    | {
        __typename: 'FdrRoundTrip';
        giataCode?: number | null;
        name: string;
        fromTo?: string | null;
        mode?: string | null;
        id: string;
        hubData?: {
          __typename?: 'FdrHubProductData';
          taReviews: number;
          taRating: number;
        } | null;
        image?: {
          __typename?: 'FdrImage';
          alt: string;
          description?: string | null;
          copyright?: string | null;
          rawUrl: string;
          desaturated?: boolean | null;
          resized: Array<{
            __typename?: 'FdrResizedImage';
            id: string;
            link: {
              __typename?: 'FdrLink';
              openMethod?: Types.FdrLinkOpenMethod | null;
              legacyUrl?: string | null;
              type: Types.FdrLinkType;
              url: string;
            };
          }>;
        } | null;
        imagesPaginated: {
          __typename: 'FdrImagesResponse';
          images?: Array<{
            __typename?: 'FdrImage';
            alt: string;
            description?: string | null;
            copyright?: string | null;
            rawUrl: string;
            desaturated?: boolean | null;
            resized: Array<{
              __typename?: 'FdrResizedImage';
              id: string;
              link: {
                __typename?: 'FdrLink';
                openMethod?: Types.FdrLinkOpenMethod | null;
                legacyUrl?: string | null;
                type: Types.FdrLinkType;
                url: string;
              };
            }>;
          }> | null;
          pagination: {
            __typename?: 'FdrPagination';
            total: number;
            page: number;
            perPage: number;
          };
        };
        disrupters?: Array<{
          __typename?: 'FdrDisrupter';
          text: string;
        }> | null;
        featureGroups?: Array<{
          __typename?: 'FdrProductFeatureGroup';
          id: string;
          name: string;
          features: Array<{
            __typename?: 'FdrProductFeature';
            name: string;
            id: string;
            enabled: boolean;
          }>;
        }> | null;
        destination: {
          __typename?: 'FdrDestination';
          name: string;
          id: string;
          country: { __typename?: 'FdrCountry'; name: string; id: string };
        };
      }
    | { __typename: 'FdrAdventureTravel' }
    | null;
};

export const FdrProductOfferItemFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'fdrProductOfferItem' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FdrProductOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'duration' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pricePerPerson' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'fdrPrice' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rooms' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'fdrOfferRoom' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'hotelToAirportDistance' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'transferIncluded' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transferPrice' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'fdrPrice' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'webMeta' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'link' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'fdrLink' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'provider' } },
          { kind: 'Field', name: { kind: 'Name', value: 'productCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'travelType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'departureDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'returnDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isInWishlist' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'co2Emission' },
            directives: [
              {
                kind: 'Directive',
                name: { kind: 'Name', value: 'include' },
                arguments: [
                  {
                    kind: 'Argument',
                    name: { kind: 'Name', value: 'if' },
                    value: {
                      kind: 'Variable',
                      name: { kind: 'Name', value: 'withCO2Emission' },
                    },
                  },
                ],
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'fdrCO2Emission' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'product' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'FdrHotel' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'fdrProductRecommenderHotel',
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'FdrRoundTrip' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'fdrProductRecommenderRoundTrip',
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'FdrCruise' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'fdrProductRecommenderCruise',
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...FdrPriceFragmentDoc.definitions,
    ...FdrOfferRoomFragmentDoc.definitions,
    ...FdrLinkFragmentDoc.definitions,
    ...FdrCo2EmissionFragmentDoc.definitions,
    ...FdrProductRecommenderHotelFragmentDoc.definitions,
    ...FdrProductRecommenderRoundTripFragmentDoc.definitions,
    ...FdrProductRecommenderCruiseFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;
