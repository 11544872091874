import styled from 'styled-components';
import { FdrImage } from '@hotelplan/fdr.regular.basis.fdr-image';
import { FdrFaq } from '@hotelplan/fdr.regular.fusion.fdr-faq';
import { FdrImage as FdrImageType } from '@hotelplan/supergraph-api';
import { sx2CssThemeFn } from '@hotelplan/util.theme.sxc';
import { Container } from 'components/domain/container';

export const FdrPageComponentWrapper = styled.div(
  sx2CssThemeFn({
    maxWidth: '1218px',
    width: '100%',
    mx: 'auto',
    px: [0, 4],
    zIndex: 2,
    '.text-block,.marketing-teaser': {
      pt: [null, 3],
      pb: ['40px', '75px'],
      px: [4, 0],
      '&.none-padding-bottom': {
        paddingBottom: 0,
      },
      '&.none-padding-top': {
        paddingTop: 0,
      },
      '&.small-padding-bottom': {
        paddingBottom: 3,
      },
      '&.small-padding-top': {
        paddingTop: 3,
      },
      '&.large-padding-bottom': {
        paddingBottom: ['40px', '75px'],
      },
      '&.large-padding-top': {
        paddingTop: ['40px', '75px'],
      },
    },
  })
);

export const FdrPageComponentStyles = styled.div<{
  backgroundColor?: string;
  fontColor?: string;
}>(({ backgroundColor, fontColor }) =>
  sx2CssThemeFn({
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',
    backgroundColor,
    color: fontColor,
  })
);

export const FdrPageComponentBackgroundImage = styled(FdrImage).attrs({
  lazy: false,
  decoding: `sync`,
  loading: `eager`,
  alt: 'background image',
} as Partial<FdrImageType>)(
  sx2CssThemeFn({
    objectFit: 'cover',
    width: '100%',
    height: '100%',
    position: 'absolute',
    display: 'block',
    maxHeight: '100%',
    zIndex: 1,
  })
);

export const FdrFaqContainer = styled(Container)(
  ({ theme: { FONT_SIZE, text } }) =>
    sx2CssThemeFn({
      px: [4, '0px'],
      '.accordionContent': {
        ...FONT_SIZE.LARGE,
        ...text.richText,
      },
      '.accordionButton': {
        ...FONT_SIZE.LARGE_BOLD,
        pl: ['20px', 4],
        pr: '20px',
        '.chevron': {
          ml: 2,
        },
      },
      '.accordionItem': {
        '.accordionButton.active': {
          backgroundColor: 'lightGreySixth',
        },
        '.accordionContent.active': {
          backgroundColor: 'white',
          mx: 3,
        },
        '.accordionContent': {
          px: [1, '10px'],
        },
      },
    })
);

export const FdrFaqWrapper = styled(FdrFaq)<{ mb?: boolean }>(({ mb }) =>
  sx2CssThemeFn({
    maxWidth: '800px',
    mb: mb ? [5, '50px'] : 0,
  })
);
