import type * as Types from '@hotelplan/supergraph-api';

import type { DocumentNode } from 'graphql';
import { FdrThemeOfferRecommendationFragmentDoc } from 'fdr/schemas/fragment/recommenders/theme/fdr-theme-offer-recommendation.generated';
import type * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@hotelplan/fdr.lib.apollo.query';
const defaultOptions = {} as const;
export type FdrThemeOfferRecommendationComponentQueryVariables = Types.Exact<{
  input: Types.FdrComponentsInput;
  themePage?: Types.Scalars['Int'];
}>;

export type FdrThemeOfferRecommendationComponentQuery = {
  __typename?: 'Query';
  fdrComponents: {
    __typename?: 'FdrComponentsResponse';
    components: Array<
      | { __typename?: 'FdrSmartSeerRecommender' }
      | { __typename?: 'FdrRssBlogArticleRecommender' }
      | { __typename?: 'FdrAccordion' }
      | { __typename?: 'FdrAccordionComponent' }
      | { __typename?: 'FdrAgencyContactModule' }
      | { __typename?: 'FdrAgencyRecommender' }
      | { __typename?: 'FdrBenefitsComponent' }
      | { __typename?: 'FdrBlogArticleRecommender' }
      | { __typename?: 'FdrBrandsComponent' }
      | { __typename?: 'FdrContactModule' }
      | { __typename?: 'FdrEmployeesComponent' }
      | { __typename?: 'FdrFaq' }
      | { __typename?: 'FdrFlightOfferRecommender' }
      | { __typename?: 'FdrFlightRecommender' }
      | { __typename?: 'FdrGeoChildrenPlaceholder' }
      | { __typename?: 'FdrGeoClimatePlaceholder' }
      | { __typename?: 'FdrGeoGalleryPlaceholder' }
      | { __typename?: 'FdrGeoLeadTextPlaceholder' }
      | { __typename?: 'FdrGeoLongTextPlaceholder' }
      | { __typename?: 'FdrGeoMapPlaceholder' }
      | { __typename?: 'FdrGeoOfferButtonPlaceholder' }
      | { __typename?: 'FdrGeoOfferRecommender' }
      | { __typename?: 'FdrGeoRecommender' }
      | { __typename?: 'FdrGeoRecommenderPlaceholder' }
      | { __typename?: 'FdrHeroMediaGallery' }
      | { __typename?: 'FdrImageText' }
      | { __typename?: 'FdrLinkList' }
      | { __typename?: 'FdrMarkdownText' }
      | { __typename?: 'FdrMarketingRecommender' }
      | { __typename?: 'FdrMarketingTeaser' }
      | { __typename?: 'FdrMediaGallery' }
      | { __typename?: 'FdrNewsArticlesRecommender' }
      | { __typename?: 'FdrNewsletterSignup' }
      | { __typename?: 'FdrProductOfferRecommender' }
      | { __typename?: 'FdrProductOffersMap' }
      | { __typename?: 'FdrProductRecommender' }
      | { __typename?: 'FdrRawText' }
      | { __typename?: 'FdrSrlButton' }
      | { __typename?: 'FdrThemeGeoRecommender' }
      | {
          __typename?: 'FdrThemeOfferRecommender';
          id: string;
          title?: string | null;
          text?: string | null;
          bgColor?: string | null;
          fgColor?: string | null;
          displayType: string;
          bgImage?: {
            __typename?: 'FdrImage';
            alt: string;
            description?: string | null;
            copyright?: string | null;
            rawUrl: string;
            desaturated?: boolean | null;
            resized: Array<{
              __typename?: 'FdrResizedImage';
              id: string;
              link: {
                __typename?: 'FdrLink';
                openMethod?: Types.FdrLinkOpenMethod | null;
                legacyUrl?: string | null;
                type: Types.FdrLinkType;
                url: string;
              };
            }>;
          } | null;
          itemsPage: {
            __typename?: 'FdrThemeOfferRecommenderSearchResult';
            pagination: {
              __typename?: 'FdrPagination';
              total: number;
              page: number;
              perPage: number;
            };
            items?: Array<{
              __typename?: 'FdrThemeOffer';
              theme: {
                __typename?: 'FdrTheme';
                id: string;
                title: string;
                image?: {
                  __typename?: 'FdrImage';
                  alt: string;
                  description?: string | null;
                  copyright?: string | null;
                  rawUrl: string;
                  desaturated?: boolean | null;
                  resized: Array<{
                    __typename?: 'FdrResizedImage';
                    id: string;
                    link: {
                      __typename?: 'FdrLink';
                      openMethod?: Types.FdrLinkOpenMethod | null;
                      legacyUrl?: string | null;
                      type: Types.FdrLinkType;
                      url: string;
                    };
                  }>;
                } | null;
              };
              offer: {
                __typename?: 'FdrProductOffer';
                duration: number;
                product?:
                  | { __typename?: 'FdrCruise'; id: string }
                  | { __typename?: 'FdrHotel'; id: string }
                  | { __typename?: 'FdrRoundTrip'; id: string }
                  | { __typename?: 'FdrAdventureTravel'; id: string }
                  | null;
                pricePerPerson: {
                  __typename?: 'FdrPrice';
                  value: number;
                  currency: string;
                };
                rooms: Array<{
                  __typename?: 'FdrProductOfferRoom';
                  childrenDobs?: Array<any> | null;
                  adults: number;
                }>;
              };
              webMeta: {
                __typename?: 'FdrSearchResultWebMeta';
                link: {
                  __typename?: 'FdrLink';
                  openMethod?: Types.FdrLinkOpenMethod | null;
                  legacyUrl?: string | null;
                  type: Types.FdrLinkType;
                  url: string;
                };
              };
            }> | null;
          };
        }
      | { __typename?: 'FdrThemeRecommender' }
      | { __typename?: 'FdrUspBoxesComponent' }
      | null
    >;
  };
};

export const FdrThemeOfferRecommendationComponentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'FdrThemeOfferRecommendationComponent' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'FdrComponentsInput' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'themePage' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
          defaultValue: { kind: 'IntValue', value: '0' },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fdrComponents' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'FdrComponentsResponse' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'components' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'fdrThemeOfferRecommendation',
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...FdrThemeOfferRecommendationFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;

/**
 * __useFdrThemeOfferRecommendationComponentQuery__
 *
 * To run a query within a React component, call `useFdrThemeOfferRecommendationComponentQuery` and pass it any options that fit your needs.
 * When your component renders, `useFdrThemeOfferRecommendationComponentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFdrThemeOfferRecommendationComponentQuery({
 *   variables: {
 *      input: // value for 'input'
 *      themePage: // value for 'themePage'
 *   },
 * });
 */
export function useFdrThemeOfferRecommendationComponentQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    FdrThemeOfferRecommendationComponentQuery,
    FdrThemeOfferRecommendationComponentQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    FdrThemeOfferRecommendationComponentQuery,
    FdrThemeOfferRecommendationComponentQueryVariables
  >(FdrThemeOfferRecommendationComponentDocument, options);
}
export function useFdrThemeOfferRecommendationComponentLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    FdrThemeOfferRecommendationComponentQuery,
    FdrThemeOfferRecommendationComponentQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    FdrThemeOfferRecommendationComponentQuery,
    FdrThemeOfferRecommendationComponentQueryVariables
  >(FdrThemeOfferRecommendationComponentDocument, options);
}
export type FdrThemeOfferRecommendationComponentQueryHookResult = ReturnType<
  typeof useFdrThemeOfferRecommendationComponentQuery
>;
export type FdrThemeOfferRecommendationComponentLazyQueryHookResult =
  ReturnType<typeof useFdrThemeOfferRecommendationComponentLazyQuery>;
export type FdrThemeOfferRecommendationComponentQueryResult =
  Apollo.QueryResult<
    FdrThemeOfferRecommendationComponentQuery,
    FdrThemeOfferRecommendationComponentQueryVariables
  >;
