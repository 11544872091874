import noop from 'lodash/noop';
import { useTranslation } from 'next-i18next';
import React, { useEffect } from 'react';
import { Icon } from '@hotelplan/components.common.icon';
import { useDeviceType } from '@hotelplan/libs.context.device-type';
import { useCombinedPagination } from '@hotelplan/libs.hooks';
import { useOnScrolledReach, ScrollDirection } from '@hotelplan/libs.hooks-dom';
import { TUnionPageComponents } from 'fdr/components/local/fdr-page-components/fdr-page-components.types';
import {
  FdrRecommendationWrap,
  FdrItemWrap,
  FdrItemSkeleton,
  FdrShowMoreButton,
  FdrShowLessButton,
  FdrMoreLessButtons,
  FdrListSkeleton,
  FdrWrapFixMobile,
} from './fdr-recommendation-list.styles';

const ITEMS_PER_PAGE = 3;

export interface IRecommendationListProps<T> {
  recommendations: T[];
  render(item: T, index: number): React.ReactNode;
  loading?: boolean;
  perPage?: number | null;
  page?: number;
  total?: number;
  onFetchMore?(): void;
  onInit?(recommendations: T[]): void;
  className?: string;
  hasMore?: boolean;
  recommendationType?: TUnionPageComponents['__typename'];
}

export const FdrRecommendationList = <T extends { __typename?: string }>({
  recommendations,
  render,
  page: currentPage,
  total,
  perPage = ITEMS_PER_PAGE,
  loading,
  onFetchMore,
  className,
  onInit = noop,
  hasMore: customHasMore,
  recommendationType,
}: IRecommendationListProps<T>) => {
  const { mobile } = useDeviceType();
  const [t] = useTranslation('common');

  const {
    items,
    nextPage,
    prevPage,
    hasMore: hasMoreCombined,
    page,
  } = useCombinedPagination(recommendations, {
    page: currentPage,
    total,
    perPage: perPage,
    fetchMore: onFetchMore,
  });

  const hasMore = customHasMore ? customHasMore : hasMoreCombined;

  const isBlogArticlesRecommender =
    recommendationType === 'FdrRssBlogArticleRecommender';

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => onInit(recommendations), []);

  const scrollElRef = useOnScrolledReach(nextPage, {
    direction: ScrollDirection.HORIZONTAL,
    enable: mobile && hasMore,
  });

  const showMoreBtnText = isBlogArticlesRecommender
    ? t('common:moreBlogArticles.button')
    : t('common:moreOffers.button');

  const showLessBtnText = isBlogArticlesRecommender
    ? t('common:lessBlogArticles.button')
    : t('common:lessOffers.button');

  return (
    <>
      <FdrRecommendationWrap ref={scrollElRef} className={className}>
        {items.map((item, i) => {
          const renderedRecommendation = render(item, i);
          const isMarketing = item.__typename === 'FdrMarketingRecommenderItem';
          const isProduct = item.__typename === 'FdrProductOffer';
          const singleItem = items.length === 1 && (isMarketing || isProduct);

          return renderedRecommendation ? (
            <FdrItemWrap
              key={i}
              single={singleItem}
              mobile={mobile}
              className="recommendations-item"
            >
              {renderedRecommendation}
            </FdrItemWrap>
          ) : null;
        })}
        {!!nextPage && loading && (
          <>
            {mobile && <FdrItemSkeleton />}
            {!mobile && <FdrListSkeleton className="list-skeleton" />}
          </>
        )}
        {items.length !== 1 && mobile ? <FdrWrapFixMobile /> : null}
      </FdrRecommendationWrap>
      {!mobile && (hasMore || page > 0) ? (
        <FdrMoreLessButtons>
          {hasMore ? (
            <FdrShowMoreButton onClick={nextPage}>
              {showMoreBtnText}
              <Icon name="chevron-down" />
            </FdrShowMoreButton>
          ) : (
            <span />
          )}
          {page > 0 && (
            <FdrShowLessButton onClick={prevPage}>
              {showLessBtnText}
              <Icon name="chevron-down" />
            </FdrShowLessButton>
          )}
        </FdrMoreLessButtons>
      ) : null}
    </>
  );
};
