import React from 'react';
import { FdrPagination } from '@hotelplan/supergraph-api';
import { TFdrRecommendationUnion } from 'fdr/components/local/fdr-recommendation-group/fdr-recommendation-group.utils';
import {
  TQueryResultWithPatchedFetch,
  TRecommendersQueryUnion,
  TFdrRecommendationFragmentUnion,
} from './component-request.types';

export function useGetFdrComponent(
  id: string,
  initialItems: Array<TFdrRecommendationUnion>,
  pagination: FdrPagination | undefined,
  useGet: (id: string, page: number) => TQueryResultWithPatchedFetch
) {
  const [items, setItems] =
    React.useState<Array<TFdrRecommendationUnion>>(initialItems);
  const [page, setPage] = React.useState<number>(0);

  const { fetchMore } = useGet(id, 0);

  return {
    items,
    page,
    hasMore: (page + 1) * pagination?.perPage < pagination?.total,
    nextPage() {
      const newPage = page + 1;
      return fetchMore(newPage)
        .then(result => {
          const typeName = (
            (result.data as TRecommendersQueryUnion).fdrComponents
              .components[0] as TFdrRecommendationFragmentUnion
          ).__typename;
          setItems(prev => [
            ...prev,
            ...((
              (result.data as TRecommendersQueryUnion).fdrComponents
                .components[0] as TFdrRecommendationFragmentUnion
            )[
              `${
                typeName === 'FdrFlightOfferRecommender'
                  ? 'flightItemsPage'
                  : 'itemsPage'
              }`
            ].items ?? []),
          ]);

          setPage(newPage);
        })
        .catch(console.error);
    },
  };
}
