import React, { ReactElement } from 'react';
import { EPageType } from '@hotelplan/fdr.lib.page.available-pages';
import { FdrPageComponent } from 'fdr/components/local/fdr-page-components/fdr-page-component';
import {
  FdrPageComponentBackgroundImage,
  FdrPageComponentStyles,
  FdrPageComponentWrapper,
} from 'fdr/components/local/fdr-page-components/fdr-page-components.styles';
import { TUnionPageComponents } from 'fdr/components/local/fdr-page-components/fdr-page-components.types';
import {
  FdrStyleSettingsDynamicComponents,
  getFdrBackgroundImage,
  getFdrStyle,
} from 'fdr/components/local/fdr-page-components/fdr-page-components.utils';

export function fdrRenderComponent(
  component: TUnionPageComponents,
  pageType: EPageType,
  idx: number,
  nextComponentTypename?: string,
  isHomeSmartSeerRecommender?: boolean
): ReactElement {
  if (
    ('itemsPage' in component && component.itemsPage.pagination.total === 0) ||
    ('flightItemsPage' in component &&
      component.flightItemsPage.pagination.total === 0) ||
    (component.__typename === 'FdrAccordionComponent' &&
      !component?.accordionComponents?.components?.length)
  ) {
    return null;
  }

  const image = getFdrBackgroundImage(component);

  return (
    <FdrPageComponentStyles
      key={idx}
      backgroundColor={
        component.__typename !== 'FdrMarketingTeaser'
          ? getFdrStyle(
              component,
              FdrStyleSettingsDynamicComponents.BACKGROUND_COLOR
            )
          : null
      }
    >
      {image ? <FdrPageComponentBackgroundImage image={image} /> : null}

      <FdrPageComponentWrapper>
        <FdrPageComponent
          id={`dc-${pageType.toLowerCase()}-${component.__typename}-${idx}`}
          component={component}
          nextComponentTypename={nextComponentTypename}
          isHomeSmartSeerRecommender={isHomeSmartSeerRecommender}
        />
      </FdrPageComponentWrapper>
    </FdrPageComponentStyles>
  );
}
