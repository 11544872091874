import { FdrImageResizeMode } from '@hotelplan/supergraph-api';
import {
  EOrientation,
  TFdrMediaMeta,
} from '@hotelplan/fdr.regular.basis.fdr-picture';

const portraitMinMaxMap = new Map<number, { maxW?: number; minW?: number }>([
  [375, { maxW: 375 }],
  [414, { maxW: 414, minW: 375.1 }],
  [768, { maxW: 768, minW: 414.1 }],
  [912, { minW: 768.1 }],
]);

const landscapeMinMaxMap = new Map<number, { maxW?: number; minW?: number }>([
  [740, { maxW: 740 }],
  [915, { maxW: 915, minW: 740.1 }],
  [1024, { maxW: 1024, minW: 915.1 }],
  [1368, { maxW: 1368, minW: 1024.1 }],
  [1920, { maxW: 1920, minW: 1368.1 }],
  [2560, { maxW: 2560, minW: 1920.1 }],
  [3440, { maxW: 3440, minW: 2560.1 }],
  [3840, { minW: 3440.1 }],
]);

const minMaxMap = new Map<
  EOrientation,
  Map<number, { maxW?: number; minW?: number }>
>([
  [EOrientation.portrait, portraitMinMaxMap],
  [EOrientation.landscape, landscapeMinMaxMap],
]);

export function mediaCfg(
  orientation: EOrientation,
  w: number,
  h: number,
  mode: FdrImageResizeMode = FdrImageResizeMode.Auto
): TFdrMediaMeta {
  const q = minMaxMap?.get(orientation)?.get(w);

  return {
    s: { width: w, height: h },
    q,
    orientation,
    mode,
  };
}

export function pCfg(
  w: number,
  h: number,
  mode: FdrImageResizeMode
): TFdrMediaMeta {
  return mediaCfg(EOrientation.portrait, w, h, mode);
}

export function lCfg(
  w: number,
  h: number,
  mode: FdrImageResizeMode
): TFdrMediaMeta {
  return mediaCfg(EOrientation.landscape, w, h, mode);
}
