import type * as Types from '@hotelplan/supergraph-api';

import type { DocumentNode } from 'graphql';
import { FdrBlogArticleRecommendationFragmentDoc } from 'fdr/schemas/fragment/recommenders/blog-article/fdr-blog-article-recommendation.generated';
import type * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@hotelplan/fdr.lib.apollo.query';
const defaultOptions = {} as const;
export type FdrBlogArticleRecommendationComponentQueryVariables = Types.Exact<{
  input: Types.FdrComponentsInput;
  blogArticlePage?: Types.Scalars['Int'];
}>;

export type FdrBlogArticleRecommendationComponentQuery = {
  __typename?: 'Query';
  fdrComponents: {
    __typename?: 'FdrComponentsResponse';
    components: Array<
      | { __typename?: 'FdrSmartSeerRecommender' }
      | {
          __typename?: 'FdrRssBlogArticleRecommender';
          id: string;
          text?: string | null;
          fgColor?: string | null;
          bgColor?: string | null;
          title?: string | null;
          bgImage?: {
            __typename?: 'FdrImage';
            alt: string;
            description?: string | null;
            copyright?: string | null;
            rawUrl: string;
            desaturated?: boolean | null;
            resized: Array<{
              __typename?: 'FdrResizedImage';
              id: string;
              link: {
                __typename?: 'FdrLink';
                openMethod?: Types.FdrLinkOpenMethod | null;
                legacyUrl?: string | null;
                type: Types.FdrLinkType;
                url: string;
              };
            }>;
          } | null;
          itemsPage: {
            __typename?: 'FdrRssBlogArticlePage';
            pagination: {
              __typename?: 'FdrPagination';
              total: number;
              page: number;
              perPage: number;
            };
            items?: Array<{
              __typename?: 'FdrRssBlogArticle';
              text: string;
              author?: string | null;
              date: string;
              title: string;
              link: {
                __typename?: 'FdrLink';
                openMethod?: Types.FdrLinkOpenMethod | null;
                legacyUrl?: string | null;
                type: Types.FdrLinkType;
                url: string;
              };
              image?: {
                __typename?: 'FdrImage';
                alt: string;
                description?: string | null;
                copyright?: string | null;
                rawUrl: string;
                desaturated?: boolean | null;
                resized: Array<{
                  __typename?: 'FdrResizedImage';
                  id: string;
                  link: {
                    __typename?: 'FdrLink';
                    openMethod?: Types.FdrLinkOpenMethod | null;
                    legacyUrl?: string | null;
                    type: Types.FdrLinkType;
                    url: string;
                  };
                }>;
              } | null;
            }> | null;
          };
        }
      | { __typename?: 'FdrAccordion' }
      | { __typename?: 'FdrAccordionComponent' }
      | { __typename?: 'FdrAgencyContactModule' }
      | { __typename?: 'FdrAgencyRecommender' }
      | { __typename?: 'FdrBenefitsComponent' }
      | { __typename?: 'FdrBlogArticleRecommender' }
      | { __typename?: 'FdrBrandsComponent' }
      | { __typename?: 'FdrContactModule' }
      | { __typename?: 'FdrEmployeesComponent' }
      | { __typename?: 'FdrFaq' }
      | { __typename?: 'FdrFlightOfferRecommender' }
      | { __typename?: 'FdrFlightRecommender' }
      | { __typename?: 'FdrGeoChildrenPlaceholder' }
      | { __typename?: 'FdrGeoClimatePlaceholder' }
      | { __typename?: 'FdrGeoGalleryPlaceholder' }
      | { __typename?: 'FdrGeoLeadTextPlaceholder' }
      | { __typename?: 'FdrGeoLongTextPlaceholder' }
      | { __typename?: 'FdrGeoMapPlaceholder' }
      | { __typename?: 'FdrGeoOfferButtonPlaceholder' }
      | { __typename?: 'FdrGeoOfferRecommender' }
      | { __typename?: 'FdrGeoRecommender' }
      | { __typename?: 'FdrGeoRecommenderPlaceholder' }
      | { __typename?: 'FdrHeroMediaGallery' }
      | { __typename?: 'FdrImageText' }
      | { __typename?: 'FdrLinkList' }
      | { __typename?: 'FdrMarkdownText' }
      | { __typename?: 'FdrMarketingRecommender' }
      | { __typename?: 'FdrMarketingTeaser' }
      | { __typename?: 'FdrMediaGallery' }
      | { __typename?: 'FdrNewsArticlesRecommender' }
      | { __typename?: 'FdrNewsletterSignup' }
      | { __typename?: 'FdrProductOfferRecommender' }
      | { __typename?: 'FdrProductOffersMap' }
      | { __typename?: 'FdrProductRecommender' }
      | { __typename?: 'FdrRawText' }
      | { __typename?: 'FdrSrlButton' }
      | { __typename?: 'FdrThemeGeoRecommender' }
      | { __typename?: 'FdrThemeOfferRecommender' }
      | { __typename?: 'FdrThemeRecommender' }
      | { __typename?: 'FdrUspBoxesComponent' }
      | null
    >;
  };
};

export const FdrBlogArticleRecommendationComponentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'FdrBlogArticleRecommendationComponent' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'FdrComponentsInput' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'blogArticlePage' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
          defaultValue: { kind: 'IntValue', value: '0' },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fdrComponents' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'FdrComponentsResponse' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'components' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'fdrBlogArticleRecommendation',
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...FdrBlogArticleRecommendationFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;

/**
 * __useFdrBlogArticleRecommendationComponentQuery__
 *
 * To run a query within a React component, call `useFdrBlogArticleRecommendationComponentQuery` and pass it any options that fit your needs.
 * When your component renders, `useFdrBlogArticleRecommendationComponentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFdrBlogArticleRecommendationComponentQuery({
 *   variables: {
 *      input: // value for 'input'
 *      blogArticlePage: // value for 'blogArticlePage'
 *   },
 * });
 */
export function useFdrBlogArticleRecommendationComponentQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    FdrBlogArticleRecommendationComponentQuery,
    FdrBlogArticleRecommendationComponentQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    FdrBlogArticleRecommendationComponentQuery,
    FdrBlogArticleRecommendationComponentQueryVariables
  >(FdrBlogArticleRecommendationComponentDocument, options);
}
export function useFdrBlogArticleRecommendationComponentLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    FdrBlogArticleRecommendationComponentQuery,
    FdrBlogArticleRecommendationComponentQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    FdrBlogArticleRecommendationComponentQuery,
    FdrBlogArticleRecommendationComponentQueryVariables
  >(FdrBlogArticleRecommendationComponentDocument, options);
}
export type FdrBlogArticleRecommendationComponentQueryHookResult = ReturnType<
  typeof useFdrBlogArticleRecommendationComponentQuery
>;
export type FdrBlogArticleRecommendationComponentLazyQueryHookResult =
  ReturnType<typeof useFdrBlogArticleRecommendationComponentLazyQuery>;
export type FdrBlogArticleRecommendationComponentQueryResult =
  Apollo.QueryResult<
    FdrBlogArticleRecommendationComponentQuery,
    FdrBlogArticleRecommendationComponentQueryVariables
  >;
