import type * as Types from '@hotelplan/supergraph-api';

import type { DocumentNode } from 'graphql';
import { FdrBgImageFragmentDoc } from 'fdr/schemas/fragment/regular/fdr-bg-image.generated';
import { FdrPaginationFragmentDoc } from 'fdr/schemas/fragment/regular/fdr-pagination.generated';
import { FdrThemeOfferItemFragmentDoc } from './fdr-theme-offer-recommendation-item.generated';
export type FdrThemeOfferRecommendationFragment = {
  __typename?: 'FdrThemeOfferRecommender';
  id: string;
  title?: string | null;
  text?: string | null;
  bgColor?: string | null;
  fgColor?: string | null;
  displayType: string;
  bgImage?: {
    __typename?: 'FdrImage';
    alt: string;
    description?: string | null;
    copyright?: string | null;
    rawUrl: string;
    desaturated?: boolean | null;
    resized: Array<{
      __typename?: 'FdrResizedImage';
      id: string;
      link: {
        __typename?: 'FdrLink';
        openMethod?: Types.FdrLinkOpenMethod | null;
        legacyUrl?: string | null;
        type: Types.FdrLinkType;
        url: string;
      };
    }>;
  } | null;
  itemsPage: {
    __typename?: 'FdrThemeOfferRecommenderSearchResult';
    pagination: {
      __typename?: 'FdrPagination';
      total: number;
      page: number;
      perPage: number;
    };
    items?: Array<{
      __typename?: 'FdrThemeOffer';
      theme: {
        __typename?: 'FdrTheme';
        id: string;
        title: string;
        image?: {
          __typename?: 'FdrImage';
          alt: string;
          description?: string | null;
          copyright?: string | null;
          rawUrl: string;
          desaturated?: boolean | null;
          resized: Array<{
            __typename?: 'FdrResizedImage';
            id: string;
            link: {
              __typename?: 'FdrLink';
              openMethod?: Types.FdrLinkOpenMethod | null;
              legacyUrl?: string | null;
              type: Types.FdrLinkType;
              url: string;
            };
          }>;
        } | null;
      };
      offer: {
        __typename?: 'FdrProductOffer';
        duration: number;
        product?:
          | { __typename?: 'FdrCruise'; id: string }
          | { __typename?: 'FdrHotel'; id: string }
          | { __typename?: 'FdrRoundTrip'; id: string }
          | { __typename?: 'FdrAdventureTravel'; id: string }
          | null;
        pricePerPerson: {
          __typename?: 'FdrPrice';
          value: number;
          currency: string;
        };
        rooms: Array<{
          __typename?: 'FdrProductOfferRoom';
          childrenDobs?: Array<any> | null;
          adults: number;
        }>;
      };
      webMeta: {
        __typename?: 'FdrSearchResultWebMeta';
        link: {
          __typename?: 'FdrLink';
          openMethod?: Types.FdrLinkOpenMethod | null;
          legacyUrl?: string | null;
          type: Types.FdrLinkType;
          url: string;
        };
      };
    }> | null;
  };
};

export const FdrThemeOfferRecommendationFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'fdrThemeOfferRecommendation' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FdrThemeOfferRecommender' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bgColor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fgColor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bgImage' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'fdrBgImage' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'itemsPage' },
            name: { kind: 'Name', value: 'themeOfferRecommenderSearchResult' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'pagination' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'page' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'themePage' },
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'perPage' },
                            value: { kind: 'IntValue', value: '3' },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pagination' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'fdrPagination' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'items' },
                  name: { kind: 'Name', value: 'themeOffers' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'fdrThemeOfferItem' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...FdrBgImageFragmentDoc.definitions,
    ...FdrPaginationFragmentDoc.definitions,
    ...FdrThemeOfferItemFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;
