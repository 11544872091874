import React from 'react';
import styled from 'styled-components';
import { FdrMediaGalleryLazy } from '@hotelplan/fdr.regular.fusion.fdr-media-gallery';
import { TFdrMediaGalleryItem } from '@hotelplan/fdr.regular.fusion.fdr-media-gallery/fdr-media-gallery.types';
import { sx2CssThemeFn } from '@hotelplan/util.theme.sxc';
import { FdrMediaGalleryFragment } from 'fdr/schemas/fragment/media-gallery/fdr-media-gallery.generated';

const FdrMediaGalleryWrapper = styled.div(
  sx2CssThemeFn({
    mb: [4, '50px'],
  }),
  ({ theme }) => ({
    '.media-gallery': theme.mediaGallery.mediaGalleryStyles,
  })
);

export const FdrMediaGalleryPageComponents: React.FC<{
  component: FdrMediaGalleryFragment;
}> = ({ component }) => {
  return (
    <FdrMediaGalleryWrapper>
      <FdrMediaGalleryLazy
        items={component.media as TFdrMediaGalleryItem[]}
        hasPreview
        isShowIndex={false}
        className="media-gallery"
      />
    </FdrMediaGalleryWrapper>
  );
};
