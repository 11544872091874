import camelCase from 'lodash/camelCase';
import { RecommendationsLoadedPayload } from '@hotelplan/libs.tracking';
import { FdrSmartSeerRecommenderStatus } from '@hotelplan/supergraph-api';
import { IFdrSmartSeerTracking } from 'fdr/components/local/fdr-page-components/recommendations/smart-seer/smart-seer-recommendation.types';
import { FdrBlogArticleRecommendationItemFragment } from 'fdr/schemas/fragment/recommenders/blog-article/fdr-blog-article-recommendation-item.generated';
import { FdrFlightOfferItemFragment } from 'fdr/schemas/fragment/recommenders/flight/fdr-flight-offer-recommendation-item.generated';
import { FdrFlightItemFragment } from 'fdr/schemas/fragment/recommenders/flight/fdr-flight-recommendation-item.generated';
import { FdrGeoOfferItemFragment } from 'fdr/schemas/fragment/recommenders/geo/fdr-geo-offer-item.generated';
import { FdrMarketingItemFragment } from 'fdr/schemas/fragment/recommenders/marketing/fdr-marketing-recommendation.generated';
import { FdrProductOfferItemFragment } from 'fdr/schemas/fragment/recommenders/product/fdr-product-offer-recommendation-item.generated';
import { FdrThemeOfferItemFragment } from 'fdr/schemas/fragment/recommenders/theme/fdr-theme-offer-recommendation-item.generated';

export type RecommendationItemT =
  | FdrGeoOfferItemFragment
  | FdrMarketingItemFragment
  | FdrThemeOfferItemFragment
  | FdrProductOfferItemFragment
  | FdrBlogArticleRecommendationItemFragment
  | FdrFlightOfferItemFragment
  | FdrFlightItemFragment;

const isSmartSeerSuccessStatus = (
  status?: FdrSmartSeerRecommenderStatus
): boolean => {
  return status === FdrSmartSeerRecommenderStatus.Success;
};

export const mapFdrRecommendationsToTrackableData = (
  name: string,
  items: RecommendationItemT[],
  tracking?: IFdrSmartSeerTracking | null
): RecommendationsLoadedPayload => {
  const payload: RecommendationsLoadedPayload = {
    type: '',
    title: name,
  };
  items.forEach(item => {
    switch (item.__typename) {
      case 'FdrGeoOffer': {
        const newItem = {
          id: item.geo.id,
          price: item.topOffer.pricePerPerson.value.toString(),
          count: item.productCount,
        };
        payload.type = 'destinations';
        payload.groups = payload.groups
          ? [...payload.groups, newItem]
          : [newItem];
        break;
      }
      case 'FdrThemeOffer': {
        const newItem = {
          count: 0,
          id: item.theme.id,
          price: item.offer.pricePerPerson.value.toString(),
        };
        payload.type = 'themes';
        payload.themes = payload.themes
          ? [...payload.themes, newItem]
          : [newItem];
        break;
      }
      case 'FdrProductOffer': {
        if (item?.product?.__typename === 'FdrAdventureTravel') break;

        if (tracking) {
          payload.type = 'bd4t';
          if (isSmartSeerSuccessStatus(tracking?.status)) {
            const newItem = {
              id: item.product.giataCode.toString(),
              price: item.pricePerPerson.value.toString(),
            };
            payload.products = payload.products
              ? [...payload.products, newItem]
              : [newItem];
            payload.bd4t = {
              placementID: tracking?.placementId || '',
              ptoken: tracking?.pToken || '',
              info: {
                placementId: tracking?.placementId || '',
                modelId: tracking?.modelId || '',
                strategy: tracking?.strategy || '',
              },
              count: tracking?.count || 0,
            };
          } else {
            payload.bd4t = {
              placementID: tracking?.placementId || '',
              error: camelCase(tracking?.status || ''),
            };
          }
          break;
        } else {
          const newItem = {
            id: item?.product?.giataCode?.toString() || '',
            price: item?.pricePerPerson?.value?.toString() || '',
          };
          payload.type = 'products';
          payload.products = payload.products
            ? [...payload.products, newItem]
            : [newItem];
        }
        break;
      }
      case 'FdrMarketingRecommenderItem': {
        payload.type = 'static';
        break;
      }
      case 'FdrFlightRecommenderOfferItem': {
        const newItem = {
          code: item.offer.id,
          count: item.amount,
          price: item.offer.perPersonPrice.value.toString(),
        };
        payload.type = 'flights';
        payload.flights = payload.flights
          ? [...payload.flights, newItem]
          : [newItem];
        break;
      }
      case 'FdrFlightRecommenderItem': {
        const newItem = {
          code: item.title,
          price: '',
        };
        payload.type = 'flights';
        payload.flights = payload.flights
          ? [...payload.flights, newItem]
          : [newItem];
        break;
      }
      default:
        break;
    }
  });

  return payload;
};
