import type * as Types from '@hotelplan/supergraph-api';

import type { DocumentNode } from 'graphql';
import { FdrBgImageFragmentDoc } from 'fdr/schemas/fragment/regular/fdr-bg-image.generated';
import { FdrPaginationFragmentDoc } from 'fdr/schemas/fragment/regular/fdr-pagination.generated';
import { FdrProductOfferItemFragmentDoc } from 'fdr/schemas/fragment/recommenders/product/fdr-product-offer-recommendation-item.generated';
import { SmartSeerTrackingFragmentDoc } from './fdr-smart-seer-tracking.generated';
export type FdrSmartSeerRecommendationFragment = {
  __typename?: 'FdrSmartSeerRecommender';
  id: string;
  title?: string | null;
  text?: string | null;
  bgColor?: string | null;
  fgColor?: string | null;
  inPageNavigable: boolean;
  bgImage?: {
    __typename?: 'FdrImage';
    alt: string;
    description?: string | null;
    copyright?: string | null;
    rawUrl: string;
    desaturated?: boolean | null;
    resized: Array<{
      __typename?: 'FdrResizedImage';
      id: string;
      link: {
        __typename?: 'FdrLink';
        openMethod?: Types.FdrLinkOpenMethod | null;
        legacyUrl?: string | null;
        type: Types.FdrLinkType;
        url: string;
      };
    }>;
  } | null;
  itemsPage: {
    __typename?: 'FdrSmartSeerRecommenderSearchResult';
    placementId: string;
    pagination: {
      __typename?: 'FdrPagination';
      total: number;
      page: number;
      perPage: number;
    };
    items?: Array<{
      __typename?: 'FdrProductOffer';
      id: string;
      duration: number;
      hotelToAirportDistance?: number | null;
      transferIncluded: boolean;
      provider?: string | null;
      productCode?: string | null;
      travelType: Types.FdrProductTravelType;
      departureDate: any;
      returnDate: any;
      isInWishlist?: boolean | null;
      pricePerPerson: {
        __typename?: 'FdrPrice';
        value: number;
        currency: string;
      };
      rooms: Array<{
        __typename?: 'FdrProductOfferRoom';
        adults: number;
        childrenDobs?: Array<any> | null;
        mealDescription: string;
        roomDescription: string;
        bookingCode: string;
        mealType: string;
        roomType: string;
      }>;
      transferPrice?: {
        __typename?: 'FdrPrice';
        value: number;
        currency: string;
      } | null;
      webMeta: {
        __typename?: 'FdrSearchResultWebMeta';
        link: {
          __typename?: 'FdrLink';
          openMethod?: Types.FdrLinkOpenMethod | null;
          legacyUrl?: string | null;
          type: Types.FdrLinkType;
          url: string;
        };
      };
      co2Emission?: {
        __typename?: 'FdrCO2Emission';
        flightEmissionKgCO2e?: number | null;
        hotelEmissionKgCO2e?: number | null;
      } | null;
      product?:
        | {
            __typename: 'FdrCruise';
            giataCode?: number | null;
            name: string;
            fromTo?: string | null;
            mode?: string | null;
            id: string;
            hubData?: {
              __typename?: 'FdrHubProductData';
              taReviews: number;
              taRating: number;
            } | null;
            images?: Array<{
              __typename?: 'FdrImage';
              alt: string;
              description?: string | null;
              copyright?: string | null;
              rawUrl: string;
              desaturated?: boolean | null;
              resized: Array<{
                __typename?: 'FdrResizedImage';
                id: string;
                link: {
                  __typename?: 'FdrLink';
                  openMethod?: Types.FdrLinkOpenMethod | null;
                  legacyUrl?: string | null;
                  type: Types.FdrLinkType;
                  url: string;
                };
              }>;
            }> | null;
            imagesPaginated: {
              __typename: 'FdrImagesResponse';
              images?: Array<{
                __typename?: 'FdrImage';
                alt: string;
                description?: string | null;
                copyright?: string | null;
                rawUrl: string;
                desaturated?: boolean | null;
                resized: Array<{
                  __typename?: 'FdrResizedImage';
                  id: string;
                  link: {
                    __typename?: 'FdrLink';
                    openMethod?: Types.FdrLinkOpenMethod | null;
                    legacyUrl?: string | null;
                    type: Types.FdrLinkType;
                    url: string;
                  };
                }>;
              }> | null;
              pagination: {
                __typename?: 'FdrPagination';
                total: number;
                page: number;
                perPage: number;
              };
            };
            disrupters?: Array<{
              __typename?: 'FdrDisrupter';
              text: string;
            }> | null;
            featureGroups?: Array<{
              __typename?: 'FdrProductFeatureGroup';
              id: string;
              name: string;
              features: Array<{
                __typename?: 'FdrProductFeature';
                name: string;
                id: string;
                enabled: boolean;
              }>;
            }> | null;
            destination: {
              __typename?: 'FdrDestination';
              name: string;
              id: string;
              country: { __typename?: 'FdrCountry'; name: string; id: string };
            };
          }
        | {
            __typename: 'FdrHotel';
            giataCode?: number | null;
            name: string;
            rating: number;
            id: string;
            flags?: {
              __typename?: 'FdrHotelFlags';
              typeFlags?: {
                __typename?: 'FdrHotelTypeFlags';
                apartment: boolean;
              } | null;
            } | null;
            tripAdvisorReviews?: {
              __typename?: 'FdrTripAdvisorReviewsResponse';
              totalRating?: number | null;
              numReviews?: number | null;
            } | null;
            disrupters?: Array<{
              __typename?: 'FdrDisrupter';
              text: string;
            }> | null;
            image?: {
              __typename?: 'FdrImage';
              alt: string;
              description?: string | null;
              copyright?: string | null;
              rawUrl: string;
              desaturated?: boolean | null;
              resized: Array<{
                __typename?: 'FdrResizedImage';
                id: string;
                link: {
                  __typename?: 'FdrLink';
                  openMethod?: Types.FdrLinkOpenMethod | null;
                  legacyUrl?: string | null;
                  type: Types.FdrLinkType;
                  url: string;
                };
              }>;
            } | null;
            imagesPaginated: {
              __typename: 'FdrImagesResponse';
              images?: Array<{
                __typename?: 'FdrImage';
                alt: string;
                description?: string | null;
                copyright?: string | null;
                rawUrl: string;
                desaturated?: boolean | null;
                resized: Array<{
                  __typename?: 'FdrResizedImage';
                  id: string;
                  link: {
                    __typename?: 'FdrLink';
                    openMethod?: Types.FdrLinkOpenMethod | null;
                    legacyUrl?: string | null;
                    type: Types.FdrLinkType;
                    url: string;
                  };
                }>;
              }> | null;
              pagination: {
                __typename?: 'FdrPagination';
                total: number;
                page: number;
                perPage: number;
              };
            };
            featureGroups?: Array<{
              __typename?: 'FdrProductFeatureGroup';
              id: string;
              name: string;
              features: Array<{
                __typename?: 'FdrProductFeature';
                name: string;
                id: string;
                enabled: boolean;
              }>;
            }> | null;
            resort: {
              __typename?: 'FdrResort';
              name: string;
              id: string;
              destination: {
                __typename?: 'FdrDestination';
                name: string;
                id: string;
                country: {
                  __typename?: 'FdrCountry';
                  name: string;
                  id: string;
                };
              };
            };
          }
        | {
            __typename: 'FdrRoundTrip';
            giataCode?: number | null;
            name: string;
            fromTo?: string | null;
            mode?: string | null;
            id: string;
            hubData?: {
              __typename?: 'FdrHubProductData';
              taReviews: number;
              taRating: number;
            } | null;
            image?: {
              __typename?: 'FdrImage';
              alt: string;
              description?: string | null;
              copyright?: string | null;
              rawUrl: string;
              desaturated?: boolean | null;
              resized: Array<{
                __typename?: 'FdrResizedImage';
                id: string;
                link: {
                  __typename?: 'FdrLink';
                  openMethod?: Types.FdrLinkOpenMethod | null;
                  legacyUrl?: string | null;
                  type: Types.FdrLinkType;
                  url: string;
                };
              }>;
            } | null;
            imagesPaginated: {
              __typename: 'FdrImagesResponse';
              images?: Array<{
                __typename?: 'FdrImage';
                alt: string;
                description?: string | null;
                copyright?: string | null;
                rawUrl: string;
                desaturated?: boolean | null;
                resized: Array<{
                  __typename?: 'FdrResizedImage';
                  id: string;
                  link: {
                    __typename?: 'FdrLink';
                    openMethod?: Types.FdrLinkOpenMethod | null;
                    legacyUrl?: string | null;
                    type: Types.FdrLinkType;
                    url: string;
                  };
                }>;
              }> | null;
              pagination: {
                __typename?: 'FdrPagination';
                total: number;
                page: number;
                perPage: number;
              };
            };
            disrupters?: Array<{
              __typename?: 'FdrDisrupter';
              text: string;
            }> | null;
            featureGroups?: Array<{
              __typename?: 'FdrProductFeatureGroup';
              id: string;
              name: string;
              features: Array<{
                __typename?: 'FdrProductFeature';
                name: string;
                id: string;
                enabled: boolean;
              }>;
            }> | null;
            destination: {
              __typename?: 'FdrDestination';
              name: string;
              id: string;
              country: { __typename?: 'FdrCountry'; name: string; id: string };
            };
          }
        | { __typename: 'FdrAdventureTravel' }
        | null;
    }> | null;
    tracking: {
      __typename?: 'FdrSmartSeerRecommenderTracking';
      giataCodes?: Array<string> | null;
      modelId?: string | null;
      pToken?: string | null;
      status: Types.FdrSmartSeerRecommenderStatus;
      strategy?: string | null;
    };
  };
};

export const FdrSmartSeerRecommendationFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'fdrSmartSeerRecommendation' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FdrSmartSeerRecommender' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bgColor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fgColor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'inPageNavigable' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bgImage' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'fdrBgImage' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'itemsPage' },
            name: { kind: 'Name', value: 'searchResult' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'pagination' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'page' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'smartSeerPage' },
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'perPage' },
                            value: { kind: 'IntValue', value: '3' },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'smartSeerUserId' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'smartSeerUserId' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'currentUrl' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'currentUrl' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'gss' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'gss' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pagination' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'fdrPagination' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'items' },
                  name: { kind: 'Name', value: 'offersPage' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'fdrProductOfferItem' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'placementId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'tracking' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'smartSeerTracking' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...FdrBgImageFragmentDoc.definitions,
    ...FdrPaginationFragmentDoc.definitions,
    ...FdrProductOfferItemFragmentDoc.definitions,
    ...SmartSeerTrackingFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;
