import { useRouter } from 'next/router';
import React, { FC } from 'react';
import { EPageType } from '@hotelplan/fdr.lib.page.available-pages';
import { usePageType } from '@hotelplan/libs.context.page-type';
import { useIsomorphicLayoutEffect } from '@hotelplan/libs.hooks-react';
import { fdrRenderComponent } from 'fdr/components/local/fdr-page-components/fdr-render-component.utils';
import { FdrPageComponentsContextProvider } from './fdr-page-components-context';
import { TUnionPageComponents } from './fdr-page-components.types';

export interface IFdrPageComponentsProps {
  components?: TUnionPageComponents[];
  className?: string;
  isHomeSmartSeerRecommender?: boolean;
}

export const FdrPageComponents: FC<IFdrPageComponentsProps> = ({
  components,
  className,
  isHomeSmartSeerRecommender,
}) => {
  const { asPath: uri } = useRouter();

  const pageType = usePageType<EPageType>();

  useIsomorphicLayoutEffect(() => {
    window.history.scrollRestoration = 'manual';
    const elemId = uri.replace(/.*\#/, '');

    const rId = requestAnimationFrame(() => {
      document.getElementById(elemId)?.scrollIntoView();
    });
    return () => cancelAnimationFrame(rId);
  }, [uri, components]);

  if (!components?.length) return null;

  return (
    <section data-id="dynamic-components" className={className}>
      <FdrPageComponentsContextProvider value={{ count: components.length }}>
        {components.map((component, i) => {
          const nextComponentTypename = components[i + 1]?.__typename;

          return fdrRenderComponent(
            component,
            pageType,
            i,
            nextComponentTypename,
            isHomeSmartSeerRecommender
          );
        })}
      </FdrPageComponentsContextProvider>
    </section>
  );
};
