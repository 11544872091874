import type * as Types from '@hotelplan/supergraph-api';

import type { DocumentNode } from 'graphql';
import { FdrGeoOfferRecommendationFragmentDoc } from 'fdr/schemas/fragment/recommenders/geo/fdr-geo-offer-recommendation.generated';
import type * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@hotelplan/fdr.lib.apollo.query';
const defaultOptions = {} as const;
export type FdrGeoOfferRecommendationComponentQueryVariables = Types.Exact<{
  input: Types.FdrComponentsInput;
  geoPage?: Types.Scalars['Int'];
}>;

export type FdrGeoOfferRecommendationComponentQuery = {
  __typename?: 'Query';
  fdrComponents: {
    __typename?: 'FdrComponentsResponse';
    components: Array<
      | { __typename?: 'FdrSmartSeerRecommender' }
      | { __typename?: 'FdrRssBlogArticleRecommender' }
      | { __typename?: 'FdrAccordion' }
      | { __typename?: 'FdrAccordionComponent' }
      | { __typename?: 'FdrAgencyContactModule' }
      | { __typename?: 'FdrAgencyRecommender' }
      | { __typename?: 'FdrBenefitsComponent' }
      | { __typename?: 'FdrBlogArticleRecommender' }
      | { __typename?: 'FdrBrandsComponent' }
      | { __typename?: 'FdrContactModule' }
      | { __typename?: 'FdrEmployeesComponent' }
      | { __typename?: 'FdrFaq' }
      | { __typename?: 'FdrFlightOfferRecommender' }
      | { __typename?: 'FdrFlightRecommender' }
      | { __typename?: 'FdrGeoChildrenPlaceholder' }
      | { __typename?: 'FdrGeoClimatePlaceholder' }
      | { __typename?: 'FdrGeoGalleryPlaceholder' }
      | { __typename?: 'FdrGeoLeadTextPlaceholder' }
      | { __typename?: 'FdrGeoLongTextPlaceholder' }
      | { __typename?: 'FdrGeoMapPlaceholder' }
      | { __typename?: 'FdrGeoOfferButtonPlaceholder' }
      | {
          __typename: 'FdrGeoOfferRecommender';
          text?: string | null;
          title?: string | null;
          titleFgColor?: string | null;
          id: string;
          fgColor?: string | null;
          displayType: string;
          bgImage?: {
            __typename?: 'FdrImage';
            alt: string;
            description?: string | null;
            copyright?: string | null;
            rawUrl: string;
            desaturated?: boolean | null;
            resized: Array<{
              __typename?: 'FdrResizedImage';
              id: string;
              link: {
                __typename?: 'FdrLink';
                openMethod?: Types.FdrLinkOpenMethod | null;
                legacyUrl?: string | null;
                type: Types.FdrLinkType;
                url: string;
              };
            }>;
          } | null;
          itemsPage: {
            __typename?: 'FdrGeoOfferRecommenderSearchResult';
            pagination: {
              __typename?: 'FdrPagination';
              total: number;
              page: number;
              perPage: number;
            };
            items?: Array<{
              __typename?: 'FdrGeoOffer';
              productCount: number;
              webMeta: {
                __typename?: 'FdrSearchResultWebMeta';
                link: {
                  __typename?: 'FdrLink';
                  openMethod?: Types.FdrLinkOpenMethod | null;
                  legacyUrl?: string | null;
                  type: Types.FdrLinkType;
                  url: string;
                };
              };
              geo?:
                | {
                    __typename?: 'FdrDestination';
                    flightTime?: number | null;
                    mapZoom: number;
                    id: string;
                    title: string;
                    country: { __typename?: 'FdrCountry'; name: string };
                    image?: {
                      __typename?: 'FdrImage';
                      alt: string;
                      description?: string | null;
                      copyright?: string | null;
                      rawUrl: string;
                      desaturated?: boolean | null;
                      resized: Array<{
                        __typename?: 'FdrResizedImage';
                        id: string;
                        link: {
                          __typename?: 'FdrLink';
                          openMethod?: Types.FdrLinkOpenMethod | null;
                          legacyUrl?: string | null;
                          type: Types.FdrLinkType;
                          url: string;
                        };
                      }>;
                    } | null;
                    coordinates?: {
                      __typename?: 'FdrCoordinates';
                      latitude: number;
                      longitude: number;
                    } | null;
                  }
                | {
                    __typename?: 'FdrCountry';
                    flightTime?: number | null;
                    mapZoom: number;
                    id: string;
                    title: string;
                    image?: {
                      __typename?: 'FdrImage';
                      alt: string;
                      description?: string | null;
                      copyright?: string | null;
                      rawUrl: string;
                      desaturated?: boolean | null;
                      resized: Array<{
                        __typename?: 'FdrResizedImage';
                        id: string;
                        link: {
                          __typename?: 'FdrLink';
                          openMethod?: Types.FdrLinkOpenMethod | null;
                          legacyUrl?: string | null;
                          type: Types.FdrLinkType;
                          url: string;
                        };
                      }>;
                    } | null;
                    coordinates?: {
                      __typename?: 'FdrCoordinates';
                      latitude: number;
                      longitude: number;
                    } | null;
                  }
                | {
                    __typename?: 'FdrContinent';
                    mapZoom: number;
                    id: string;
                    title: string;
                    image?: {
                      __typename?: 'FdrImage';
                      alt: string;
                      description?: string | null;
                      copyright?: string | null;
                      rawUrl: string;
                      desaturated?: boolean | null;
                      resized: Array<{
                        __typename?: 'FdrResizedImage';
                        id: string;
                        link: {
                          __typename?: 'FdrLink';
                          openMethod?: Types.FdrLinkOpenMethod | null;
                          legacyUrl?: string | null;
                          type: Types.FdrLinkType;
                          url: string;
                        };
                      }>;
                    } | null;
                    coordinates?: {
                      __typename?: 'FdrCoordinates';
                      latitude: number;
                      longitude: number;
                    } | null;
                  }
                | {
                    __typename?: 'FdrCountryGroup';
                    mapZoom: number;
                    id: string;
                    title: string;
                    image?: {
                      __typename?: 'FdrImage';
                      alt: string;
                      description?: string | null;
                      copyright?: string | null;
                      rawUrl: string;
                      desaturated?: boolean | null;
                      resized: Array<{
                        __typename?: 'FdrResizedImage';
                        id: string;
                        link: {
                          __typename?: 'FdrLink';
                          openMethod?: Types.FdrLinkOpenMethod | null;
                          legacyUrl?: string | null;
                          type: Types.FdrLinkType;
                          url: string;
                        };
                      }>;
                    } | null;
                    coordinates?: {
                      __typename?: 'FdrCoordinates';
                      latitude: number;
                      longitude: number;
                    } | null;
                  }
                | {
                    __typename?: 'FdrRegion';
                    flightTime?: number | null;
                    mapZoom: number;
                    id: string;
                    title: string;
                    country: { __typename?: 'FdrCountry'; name: string };
                    image?: {
                      __typename?: 'FdrImage';
                      alt: string;
                      description?: string | null;
                      copyright?: string | null;
                      rawUrl: string;
                      desaturated?: boolean | null;
                      resized: Array<{
                        __typename?: 'FdrResizedImage';
                        id: string;
                        link: {
                          __typename?: 'FdrLink';
                          openMethod?: Types.FdrLinkOpenMethod | null;
                          legacyUrl?: string | null;
                          type: Types.FdrLinkType;
                          url: string;
                        };
                      }>;
                    } | null;
                    coordinates?: {
                      __typename?: 'FdrCoordinates';
                      latitude: number;
                      longitude: number;
                    } | null;
                  }
                | {
                    __typename?: 'FdrResort';
                    flightTime?: number | null;
                    mapZoom: number;
                    id: string;
                    title: string;
                    image?: {
                      __typename?: 'FdrImage';
                      alt: string;
                      description?: string | null;
                      copyright?: string | null;
                      rawUrl: string;
                      desaturated?: boolean | null;
                      resized: Array<{
                        __typename?: 'FdrResizedImage';
                        id: string;
                        link: {
                          __typename?: 'FdrLink';
                          openMethod?: Types.FdrLinkOpenMethod | null;
                          legacyUrl?: string | null;
                          type: Types.FdrLinkType;
                          url: string;
                        };
                      }>;
                    } | null;
                    coordinates?: {
                      __typename?: 'FdrCoordinates';
                      latitude: number;
                      longitude: number;
                    } | null;
                  }
                | null;
              topOffer: {
                __typename?: 'FdrProductOffer';
                duration: number;
                averageTemperature?: number | null;
                pricePerPerson: {
                  __typename?: 'FdrPrice';
                  value: number;
                  currency: string;
                };
                rooms: Array<{
                  __typename?: 'FdrProductOfferRoom';
                  adults: number;
                  childrenDobs?: Array<any> | null;
                }>;
                product?:
                  | {
                      __typename?: 'FdrCruise';
                      id: string;
                      disrupters?: Array<{
                        __typename?: 'FdrDisrupter';
                        text: string;
                      }> | null;
                    }
                  | {
                      __typename?: 'FdrHotel';
                      id: string;
                      disrupters?: Array<{
                        __typename?: 'FdrDisrupter';
                        text: string;
                      }> | null;
                    }
                  | {
                      __typename?: 'FdrRoundTrip';
                      id: string;
                      disrupters?: Array<{
                        __typename?: 'FdrDisrupter';
                        text: string;
                      }> | null;
                    }
                  | {
                      __typename?: 'FdrAdventureTravel';
                      id: string;
                      disrupters?: Array<{
                        __typename?: 'FdrDisrupter';
                        text: string;
                      }> | null;
                    }
                  | null;
              };
            }> | null;
          };
        }
      | { __typename?: 'FdrGeoRecommender' }
      | { __typename?: 'FdrGeoRecommenderPlaceholder' }
      | { __typename?: 'FdrHeroMediaGallery' }
      | { __typename?: 'FdrImageText' }
      | { __typename?: 'FdrLinkList' }
      | { __typename?: 'FdrMarkdownText' }
      | { __typename?: 'FdrMarketingRecommender' }
      | { __typename?: 'FdrMarketingTeaser' }
      | { __typename?: 'FdrMediaGallery' }
      | { __typename?: 'FdrNewsArticlesRecommender' }
      | { __typename?: 'FdrNewsletterSignup' }
      | { __typename?: 'FdrProductOfferRecommender' }
      | { __typename?: 'FdrProductOffersMap' }
      | { __typename?: 'FdrProductRecommender' }
      | { __typename?: 'FdrRawText' }
      | { __typename?: 'FdrSrlButton' }
      | { __typename?: 'FdrThemeGeoRecommender' }
      | { __typename?: 'FdrThemeOfferRecommender' }
      | { __typename?: 'FdrThemeRecommender' }
      | { __typename?: 'FdrUspBoxesComponent' }
      | null
    >;
  };
};

export const FdrGeoOfferRecommendationComponentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'FdrGeoOfferRecommendationComponent' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'FdrComponentsInput' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'geoPage' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
          defaultValue: { kind: 'IntValue', value: '0' },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fdrComponents' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'FdrComponentsResponse' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'components' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'fdrGeoOfferRecommendation',
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...FdrGeoOfferRecommendationFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;

/**
 * __useFdrGeoOfferRecommendationComponentQuery__
 *
 * To run a query within a React component, call `useFdrGeoOfferRecommendationComponentQuery` and pass it any options that fit your needs.
 * When your component renders, `useFdrGeoOfferRecommendationComponentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFdrGeoOfferRecommendationComponentQuery({
 *   variables: {
 *      input: // value for 'input'
 *      geoPage: // value for 'geoPage'
 *   },
 * });
 */
export function useFdrGeoOfferRecommendationComponentQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    FdrGeoOfferRecommendationComponentQuery,
    FdrGeoOfferRecommendationComponentQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    FdrGeoOfferRecommendationComponentQuery,
    FdrGeoOfferRecommendationComponentQueryVariables
  >(FdrGeoOfferRecommendationComponentDocument, options);
}
export function useFdrGeoOfferRecommendationComponentLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    FdrGeoOfferRecommendationComponentQuery,
    FdrGeoOfferRecommendationComponentQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    FdrGeoOfferRecommendationComponentQuery,
    FdrGeoOfferRecommendationComponentQueryVariables
  >(FdrGeoOfferRecommendationComponentDocument, options);
}
export type FdrGeoOfferRecommendationComponentQueryHookResult = ReturnType<
  typeof useFdrGeoOfferRecommendationComponentQuery
>;
export type FdrGeoOfferRecommendationComponentLazyQueryHookResult = ReturnType<
  typeof useFdrGeoOfferRecommendationComponentLazyQuery
>;
export type FdrGeoOfferRecommendationComponentQueryResult = Apollo.QueryResult<
  FdrGeoOfferRecommendationComponentQuery,
  FdrGeoOfferRecommendationComponentQueryVariables
>;
