import type * as Types from '@hotelplan/supergraph-api';

import type { DocumentNode } from 'graphql';
import { FdrBgImageFragmentDoc } from 'fdr/schemas/fragment/regular/fdr-bg-image.generated';
import { FdrPaginationFragmentDoc } from 'fdr/schemas/fragment/regular/fdr-pagination.generated';
import { FdrBlogArticleRecommendationItemFragmentDoc } from './fdr-blog-article-recommendation-item.generated';
export type FdrBlogArticleRecommendationFragment = {
  __typename?: 'FdrRssBlogArticleRecommender';
  id: string;
  text?: string | null;
  fgColor?: string | null;
  bgColor?: string | null;
  title?: string | null;
  bgImage?: {
    __typename?: 'FdrImage';
    alt: string;
    description?: string | null;
    copyright?: string | null;
    rawUrl: string;
    desaturated?: boolean | null;
    resized: Array<{
      __typename?: 'FdrResizedImage';
      id: string;
      link: {
        __typename?: 'FdrLink';
        openMethod?: Types.FdrLinkOpenMethod | null;
        legacyUrl?: string | null;
        type: Types.FdrLinkType;
        url: string;
      };
    }>;
  } | null;
  itemsPage: {
    __typename?: 'FdrRssBlogArticlePage';
    pagination: {
      __typename?: 'FdrPagination';
      total: number;
      page: number;
      perPage: number;
    };
    items?: Array<{
      __typename?: 'FdrRssBlogArticle';
      text: string;
      author?: string | null;
      date: string;
      title: string;
      link: {
        __typename?: 'FdrLink';
        openMethod?: Types.FdrLinkOpenMethod | null;
        legacyUrl?: string | null;
        type: Types.FdrLinkType;
        url: string;
      };
      image?: {
        __typename?: 'FdrImage';
        alt: string;
        description?: string | null;
        copyright?: string | null;
        rawUrl: string;
        desaturated?: boolean | null;
        resized: Array<{
          __typename?: 'FdrResizedImage';
          id: string;
          link: {
            __typename?: 'FdrLink';
            openMethod?: Types.FdrLinkOpenMethod | null;
            legacyUrl?: string | null;
            type: Types.FdrLinkType;
            url: string;
          };
        }>;
      } | null;
    }> | null;
  };
};

export const FdrBlogArticleRecommendationFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'fdrBlogArticleRecommendation' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FdrRssBlogArticleRecommender' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fgColor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bgColor' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bgImage' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'fdrBgImage' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'itemsPage' },
            name: { kind: 'Name', value: 'blogArticlePage' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'pagination' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'page' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'blogArticlePage' },
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'perPage' },
                            value: { kind: 'IntValue', value: '3' },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pagination' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'fdrPagination' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'items' },
                  name: { kind: 'Name', value: 'blogArticles' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'fdrBlogArticleRecommendationItem',
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...FdrBgImageFragmentDoc.definitions,
    ...FdrPaginationFragmentDoc.definitions,
    ...FdrBlogArticleRecommendationItemFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;
