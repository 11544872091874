import format from 'date-fns/format';
import { useRouter } from 'next/router';
import { useMemo } from 'react';
import { convertDOBStrToISOStr } from '@hotelplan/components.common.dob-input';
import { mapFormTravelRoomsToSearchCriteriaInputRooms } from '@hotelplan/components.common.travel-rooms';
import { useRequestContextHeaders } from '@hotelplan/fdr.lib.context.request';
import { DATE_FORMAT } from '@hotelplan/fdr.lib.search.state-config';
import { useGSSContext } from '@hotelplan/fdr.lib.search.with-global-state';
import {
  FdrGlobalSearchStateCriteria,
  FdrProductTravelType,
  FdrTravelPeriodType,
} from '@hotelplan/supergraph-api';
import { getDurationsDependsOnType } from 'fdr/components/candidate/fdr-search/mappers/fdr-srl-form-to-criteria-input.mapper';
import { IFdrProductSrlControlState } from 'fdr/components/candidate/fdr-search/types/search-form.types';
import { useFdrCO2EmissionFeatureToggle } from 'fdr/components/local/fdr-co2-emission/use-fdr-co2-emission-feature-toggle';

function mapGSSToFdrGlobalSearchStateCriteria(
  globalSearchState: IFdrProductSrlControlState | null
): FdrGlobalSearchStateCriteria {
  let criteriaInput: FdrGlobalSearchStateCriteria = {};

  if (globalSearchState?.travelRooms) {
    criteriaInput = {
      rooms: mapFormTravelRoomsToSearchCriteriaInputRooms(
        globalSearchState.travelRooms
      ),
      travellers: {
        adults: globalSearchState.travelRooms.adults || 0,
        childrenDobs: globalSearchState.travelRooms.childrenDobs.map(
          convertDOBStrToISOStr
        ),

        ...(globalSearchState.travelRooms?.travellersDistribution?.rooms?.length
          ? {
              distribution:
                globalSearchState.travelRooms.travellersDistribution.rooms.map(
                  room => {
                    return {
                      adultsAmount: room.adultsAmount,
                      childrenDobs: room.childrenIdx.map(index =>
                        convertDOBStrToISOStr(
                          globalSearchState.travelRooms.childrenDobs[index - 1]
                        )
                      ),
                    };
                  }
                ),
            }
          : {}),
      },
    };
  }

  if (globalSearchState?.extended_travelType?.fdr) {
    criteriaInput = {
      ...criteriaInput,
      travelType: globalSearchState?.extended_travelType
        ?.fdr as FdrProductTravelType,
    };
  }

  if (globalSearchState?.travelDates) {
    const isFlexibleSearchType =
      globalSearchState.travelDates.searchType === FdrTravelPeriodType.Flexible;
    criteriaInput = {
      ...criteriaInput,
      [isFlexibleSearchType ? 'flexiblePeriod' : 'exactPeriod']: {
        departureDate: format(
          globalSearchState.travelDates.departureDate,
          DATE_FORMAT
        ),
        durations: isFlexibleSearchType
          ? getDurationsDependsOnType(globalSearchState.travelDates.duration)
          : globalSearchState.travelDates.duration.duration,
        returnDate: format(
          globalSearchState.travelDates.returnDate,
          DATE_FORMAT
        ),
      },
    };
  }

  return criteriaInput;
}

export const useFdrPageComponentsContext = () => {
  const { gss } = useGSSContext<IFdrProductSrlControlState>();
  const { asPath } = useRouter();
  const { bd4TravelUserId } = useRequestContextHeaders();
  const isCO2EmissionEnabled = useFdrCO2EmissionFeatureToggle();

  return useMemo(() => {
    return {
      smartSeerUserId: bd4TravelUserId,
      currentUrl: asPath,
      gss: mapGSSToFdrGlobalSearchStateCriteria(gss),
      withCO2Emission: isCO2EmissionEnabled,
    };
  }, [gss, asPath, bd4TravelUserId, isCO2EmissionEnabled]);
};
