import type { ApolloQueryResult } from '@apollo/client/core';
import { FdrProductOffer } from '@hotelplan/supergraph-api';
import { useFdrCO2EmissionFeatureToggle } from 'fdr/components/local/fdr-co2-emission/use-fdr-co2-emission-feature-toggle';
import { TQueryResultWithPatchedFetch } from 'fdr/components/local/fdr-recommendation-group/component-request/component-request.types';
import { fdrRecommendationProductImageCriteria } from 'fdr/components/util/criterias/recommendation-product-image.dmc';
import {
  useFdrProductOfferRecommendationComponentQuery,
  FdrProductOfferRecommendationComponentQuery,
} from 'fdr/schemas/query/component/fdr-product-offer-recommendation-component.generated';
import { createInactiveProductFilter } from './component-request.utils';

export function useGetFdrProductOfferRecommendationComponent(
  id: string
): TQueryResultWithPatchedFetch {
  const isCO2EmissionEnabled = useFdrCO2EmissionFeatureToggle();

  const result = useFdrProductOfferRecommendationComponentQuery({
    variables: {
      input: { ids: [id] },
      productPage: 0,
      productImageCriteria: fdrRecommendationProductImageCriteria,
      withCO2Emission: isCO2EmissionEnabled,
    },
    skip: true,
  });

  return {
    ...result,
    fetchMore(
      page: number
    ): Promise<ApolloQueryResult<FdrProductOfferRecommendationComponentQuery>> {
      return result
        .fetchMore({
          variables: {
            input: { ids: [id] },
            productPage: page,
          },
        })
        .then(
          createInactiveProductFilter<
            FdrProductOfferRecommendationComponentQuery,
            FdrProductOffer
          >(item => item.product)
        );
    },
  };
}
