import type * as Types from '@hotelplan/supergraph-api';

import type { DocumentNode } from 'graphql';
export type FdrCo2EmissionFragment = {
  __typename?: 'FdrCO2Emission';
  flightEmissionKgCO2e?: number | null;
  hotelEmissionKgCO2e?: number | null;
};

export const FdrCo2EmissionFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'fdrCO2Emission' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FdrCO2Emission' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'flightEmissionKgCO2e' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'hotelEmissionKgCO2e' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
