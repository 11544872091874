import type * as Types from '@hotelplan/supergraph-api';

import type { DocumentNode } from 'graphql';
import { FdrBgImageFragmentDoc } from 'fdr/schemas/fragment/regular/fdr-bg-image.generated';
import { FdrPaginationFragmentDoc } from 'fdr/schemas/fragment/regular/fdr-pagination.generated';
import { FdrGeoOfferItemFragmentDoc } from './fdr-geo-offer-item.generated';
export type FdrGeoOfferRecommendationFragment = {
  __typename: 'FdrGeoOfferRecommender';
  text?: string | null;
  title?: string | null;
  titleFgColor?: string | null;
  id: string;
  fgColor?: string | null;
  displayType: string;
  bgImage?: {
    __typename?: 'FdrImage';
    alt: string;
    description?: string | null;
    copyright?: string | null;
    rawUrl: string;
    desaturated?: boolean | null;
    resized: Array<{
      __typename?: 'FdrResizedImage';
      id: string;
      link: {
        __typename?: 'FdrLink';
        openMethod?: Types.FdrLinkOpenMethod | null;
        legacyUrl?: string | null;
        type: Types.FdrLinkType;
        url: string;
      };
    }>;
  } | null;
  itemsPage: {
    __typename?: 'FdrGeoOfferRecommenderSearchResult';
    pagination: {
      __typename?: 'FdrPagination';
      total: number;
      page: number;
      perPage: number;
    };
    items?: Array<{
      __typename?: 'FdrGeoOffer';
      productCount: number;
      webMeta: {
        __typename?: 'FdrSearchResultWebMeta';
        link: {
          __typename?: 'FdrLink';
          openMethod?: Types.FdrLinkOpenMethod | null;
          legacyUrl?: string | null;
          type: Types.FdrLinkType;
          url: string;
        };
      };
      geo?:
        | {
            __typename?: 'FdrDestination';
            flightTime?: number | null;
            mapZoom: number;
            id: string;
            title: string;
            country: { __typename?: 'FdrCountry'; name: string };
            image?: {
              __typename?: 'FdrImage';
              alt: string;
              description?: string | null;
              copyright?: string | null;
              rawUrl: string;
              desaturated?: boolean | null;
              resized: Array<{
                __typename?: 'FdrResizedImage';
                id: string;
                link: {
                  __typename?: 'FdrLink';
                  openMethod?: Types.FdrLinkOpenMethod | null;
                  legacyUrl?: string | null;
                  type: Types.FdrLinkType;
                  url: string;
                };
              }>;
            } | null;
            coordinates?: {
              __typename?: 'FdrCoordinates';
              latitude: number;
              longitude: number;
            } | null;
          }
        | {
            __typename?: 'FdrCountry';
            flightTime?: number | null;
            mapZoom: number;
            id: string;
            title: string;
            image?: {
              __typename?: 'FdrImage';
              alt: string;
              description?: string | null;
              copyright?: string | null;
              rawUrl: string;
              desaturated?: boolean | null;
              resized: Array<{
                __typename?: 'FdrResizedImage';
                id: string;
                link: {
                  __typename?: 'FdrLink';
                  openMethod?: Types.FdrLinkOpenMethod | null;
                  legacyUrl?: string | null;
                  type: Types.FdrLinkType;
                  url: string;
                };
              }>;
            } | null;
            coordinates?: {
              __typename?: 'FdrCoordinates';
              latitude: number;
              longitude: number;
            } | null;
          }
        | {
            __typename?: 'FdrContinent';
            mapZoom: number;
            id: string;
            title: string;
            image?: {
              __typename?: 'FdrImage';
              alt: string;
              description?: string | null;
              copyright?: string | null;
              rawUrl: string;
              desaturated?: boolean | null;
              resized: Array<{
                __typename?: 'FdrResizedImage';
                id: string;
                link: {
                  __typename?: 'FdrLink';
                  openMethod?: Types.FdrLinkOpenMethod | null;
                  legacyUrl?: string | null;
                  type: Types.FdrLinkType;
                  url: string;
                };
              }>;
            } | null;
            coordinates?: {
              __typename?: 'FdrCoordinates';
              latitude: number;
              longitude: number;
            } | null;
          }
        | {
            __typename?: 'FdrCountryGroup';
            mapZoom: number;
            id: string;
            title: string;
            image?: {
              __typename?: 'FdrImage';
              alt: string;
              description?: string | null;
              copyright?: string | null;
              rawUrl: string;
              desaturated?: boolean | null;
              resized: Array<{
                __typename?: 'FdrResizedImage';
                id: string;
                link: {
                  __typename?: 'FdrLink';
                  openMethod?: Types.FdrLinkOpenMethod | null;
                  legacyUrl?: string | null;
                  type: Types.FdrLinkType;
                  url: string;
                };
              }>;
            } | null;
            coordinates?: {
              __typename?: 'FdrCoordinates';
              latitude: number;
              longitude: number;
            } | null;
          }
        | {
            __typename?: 'FdrRegion';
            flightTime?: number | null;
            mapZoom: number;
            id: string;
            title: string;
            country: { __typename?: 'FdrCountry'; name: string };
            image?: {
              __typename?: 'FdrImage';
              alt: string;
              description?: string | null;
              copyright?: string | null;
              rawUrl: string;
              desaturated?: boolean | null;
              resized: Array<{
                __typename?: 'FdrResizedImage';
                id: string;
                link: {
                  __typename?: 'FdrLink';
                  openMethod?: Types.FdrLinkOpenMethod | null;
                  legacyUrl?: string | null;
                  type: Types.FdrLinkType;
                  url: string;
                };
              }>;
            } | null;
            coordinates?: {
              __typename?: 'FdrCoordinates';
              latitude: number;
              longitude: number;
            } | null;
          }
        | {
            __typename?: 'FdrResort';
            flightTime?: number | null;
            mapZoom: number;
            id: string;
            title: string;
            image?: {
              __typename?: 'FdrImage';
              alt: string;
              description?: string | null;
              copyright?: string | null;
              rawUrl: string;
              desaturated?: boolean | null;
              resized: Array<{
                __typename?: 'FdrResizedImage';
                id: string;
                link: {
                  __typename?: 'FdrLink';
                  openMethod?: Types.FdrLinkOpenMethod | null;
                  legacyUrl?: string | null;
                  type: Types.FdrLinkType;
                  url: string;
                };
              }>;
            } | null;
            coordinates?: {
              __typename?: 'FdrCoordinates';
              latitude: number;
              longitude: number;
            } | null;
          }
        | null;
      topOffer: {
        __typename?: 'FdrProductOffer';
        duration: number;
        averageTemperature?: number | null;
        pricePerPerson: {
          __typename?: 'FdrPrice';
          value: number;
          currency: string;
        };
        rooms: Array<{
          __typename?: 'FdrProductOfferRoom';
          adults: number;
          childrenDobs?: Array<any> | null;
        }>;
        product?:
          | {
              __typename?: 'FdrCruise';
              id: string;
              disrupters?: Array<{
                __typename?: 'FdrDisrupter';
                text: string;
              }> | null;
            }
          | {
              __typename?: 'FdrHotel';
              id: string;
              disrupters?: Array<{
                __typename?: 'FdrDisrupter';
                text: string;
              }> | null;
            }
          | {
              __typename?: 'FdrRoundTrip';
              id: string;
              disrupters?: Array<{
                __typename?: 'FdrDisrupter';
                text: string;
              }> | null;
            }
          | {
              __typename?: 'FdrAdventureTravel';
              id: string;
              disrupters?: Array<{
                __typename?: 'FdrDisrupter';
                text: string;
              }> | null;
            }
          | null;
      };
    }> | null;
  };
};

export const FdrGeoOfferRecommendationFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'fdrGeoOfferRecommendation' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FdrGeoOfferRecommender' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'titleFgColor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fgColor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bgImage' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'fdrBgImage' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'itemsPage' },
            name: { kind: 'Name', value: 'geoOfferRecommenderSearchResult' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'pagination' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'page' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'geoPage' },
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'perPage' },
                            value: { kind: 'IntValue', value: '3' },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pagination' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'fdrPagination' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'items' },
                  name: { kind: 'Name', value: 'geoOffers' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'fdrGeoOfferItem' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...FdrBgImageFragmentDoc.definitions,
    ...FdrPaginationFragmentDoc.definitions,
    ...FdrGeoOfferItemFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;
