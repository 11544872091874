import type * as Types from '@hotelplan/supergraph-api';

import type { DocumentNode } from 'graphql';
import { FdrProductOfferRecommendationFragmentDoc } from 'fdr/schemas/fragment/recommenders/product/fdr-product-offer-recommendation.generated';
import type * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@hotelplan/fdr.lib.apollo.query';
const defaultOptions = {} as const;
export type FdrProductOfferRecommendationComponentQueryVariables = Types.Exact<{
  input: Types.FdrComponentsInput;
  productPage?: Types.Scalars['Int'];
  productImageCriteria: Array<Types.FdrImageCriteria> | Types.FdrImageCriteria;
  withCO2Emission: Types.Scalars['Boolean'];
}>;

export type FdrProductOfferRecommendationComponentQuery = {
  __typename?: 'Query';
  fdrComponents: {
    __typename?: 'FdrComponentsResponse';
    components: Array<
      | { __typename?: 'FdrSmartSeerRecommender' }
      | { __typename?: 'FdrRssBlogArticleRecommender' }
      | { __typename?: 'FdrAccordion' }
      | { __typename?: 'FdrAccordionComponent' }
      | { __typename?: 'FdrAgencyContactModule' }
      | { __typename?: 'FdrAgencyRecommender' }
      | { __typename?: 'FdrBenefitsComponent' }
      | { __typename?: 'FdrBlogArticleRecommender' }
      | { __typename?: 'FdrBrandsComponent' }
      | { __typename?: 'FdrContactModule' }
      | { __typename?: 'FdrEmployeesComponent' }
      | { __typename?: 'FdrFaq' }
      | { __typename?: 'FdrFlightOfferRecommender' }
      | { __typename?: 'FdrFlightRecommender' }
      | { __typename?: 'FdrGeoChildrenPlaceholder' }
      | { __typename?: 'FdrGeoClimatePlaceholder' }
      | { __typename?: 'FdrGeoGalleryPlaceholder' }
      | { __typename?: 'FdrGeoLeadTextPlaceholder' }
      | { __typename?: 'FdrGeoLongTextPlaceholder' }
      | { __typename?: 'FdrGeoMapPlaceholder' }
      | { __typename?: 'FdrGeoOfferButtonPlaceholder' }
      | { __typename?: 'FdrGeoOfferRecommender' }
      | { __typename?: 'FdrGeoRecommender' }
      | { __typename?: 'FdrGeoRecommenderPlaceholder' }
      | { __typename?: 'FdrHeroMediaGallery' }
      | { __typename?: 'FdrImageText' }
      | { __typename?: 'FdrLinkList' }
      | { __typename?: 'FdrMarkdownText' }
      | { __typename?: 'FdrMarketingRecommender' }
      | { __typename?: 'FdrMarketingTeaser' }
      | { __typename?: 'FdrMediaGallery' }
      | { __typename?: 'FdrNewsArticlesRecommender' }
      | { __typename?: 'FdrNewsletterSignup' }
      | {
          __typename?: 'FdrProductOfferRecommender';
          id: string;
          title?: string | null;
          text?: string | null;
          bgColor?: string | null;
          fgColor?: string | null;
          bgImage?: {
            __typename?: 'FdrImage';
            alt: string;
            description?: string | null;
            copyright?: string | null;
            rawUrl: string;
            desaturated?: boolean | null;
            resized: Array<{
              __typename?: 'FdrResizedImage';
              id: string;
              link: {
                __typename?: 'FdrLink';
                openMethod?: Types.FdrLinkOpenMethod | null;
                legacyUrl?: string | null;
                type: Types.FdrLinkType;
                url: string;
              };
            }>;
          } | null;
          itemsPage: {
            __typename?: 'FdrProductSearchResult';
            pagination: {
              __typename?: 'FdrPagination';
              total: number;
              page: number;
              perPage: number;
            };
            items?: Array<{
              __typename?: 'FdrProductOffer';
              id: string;
              duration: number;
              hotelToAirportDistance?: number | null;
              transferIncluded: boolean;
              provider?: string | null;
              productCode?: string | null;
              travelType: Types.FdrProductTravelType;
              departureDate: any;
              returnDate: any;
              isInWishlist?: boolean | null;
              pricePerPerson: {
                __typename?: 'FdrPrice';
                value: number;
                currency: string;
              };
              rooms: Array<{
                __typename?: 'FdrProductOfferRoom';
                adults: number;
                childrenDobs?: Array<any> | null;
                mealDescription: string;
                roomDescription: string;
                bookingCode: string;
                mealType: string;
                roomType: string;
              }>;
              transferPrice?: {
                __typename?: 'FdrPrice';
                value: number;
                currency: string;
              } | null;
              webMeta: {
                __typename?: 'FdrSearchResultWebMeta';
                link: {
                  __typename?: 'FdrLink';
                  openMethod?: Types.FdrLinkOpenMethod | null;
                  legacyUrl?: string | null;
                  type: Types.FdrLinkType;
                  url: string;
                };
              };
              co2Emission?: {
                __typename?: 'FdrCO2Emission';
                flightEmissionKgCO2e?: number | null;
                hotelEmissionKgCO2e?: number | null;
              } | null;
              product?:
                | {
                    __typename: 'FdrCruise';
                    giataCode?: number | null;
                    name: string;
                    fromTo?: string | null;
                    mode?: string | null;
                    id: string;
                    hubData?: {
                      __typename?: 'FdrHubProductData';
                      taReviews: number;
                      taRating: number;
                    } | null;
                    images?: Array<{
                      __typename?: 'FdrImage';
                      alt: string;
                      description?: string | null;
                      copyright?: string | null;
                      rawUrl: string;
                      desaturated?: boolean | null;
                      resized: Array<{
                        __typename?: 'FdrResizedImage';
                        id: string;
                        link: {
                          __typename?: 'FdrLink';
                          openMethod?: Types.FdrLinkOpenMethod | null;
                          legacyUrl?: string | null;
                          type: Types.FdrLinkType;
                          url: string;
                        };
                      }>;
                    }> | null;
                    imagesPaginated: {
                      __typename: 'FdrImagesResponse';
                      images?: Array<{
                        __typename?: 'FdrImage';
                        alt: string;
                        description?: string | null;
                        copyright?: string | null;
                        rawUrl: string;
                        desaturated?: boolean | null;
                        resized: Array<{
                          __typename?: 'FdrResizedImage';
                          id: string;
                          link: {
                            __typename?: 'FdrLink';
                            openMethod?: Types.FdrLinkOpenMethod | null;
                            legacyUrl?: string | null;
                            type: Types.FdrLinkType;
                            url: string;
                          };
                        }>;
                      }> | null;
                      pagination: {
                        __typename?: 'FdrPagination';
                        total: number;
                        page: number;
                        perPage: number;
                      };
                    };
                    disrupters?: Array<{
                      __typename?: 'FdrDisrupter';
                      text: string;
                    }> | null;
                    featureGroups?: Array<{
                      __typename?: 'FdrProductFeatureGroup';
                      id: string;
                      name: string;
                      features: Array<{
                        __typename?: 'FdrProductFeature';
                        name: string;
                        id: string;
                        enabled: boolean;
                      }>;
                    }> | null;
                    destination: {
                      __typename?: 'FdrDestination';
                      name: string;
                      id: string;
                      country: {
                        __typename?: 'FdrCountry';
                        name: string;
                        id: string;
                      };
                    };
                  }
                | {
                    __typename: 'FdrHotel';
                    giataCode?: number | null;
                    name: string;
                    rating: number;
                    id: string;
                    flags?: {
                      __typename?: 'FdrHotelFlags';
                      typeFlags?: {
                        __typename?: 'FdrHotelTypeFlags';
                        apartment: boolean;
                      } | null;
                    } | null;
                    tripAdvisorReviews?: {
                      __typename?: 'FdrTripAdvisorReviewsResponse';
                      totalRating?: number | null;
                      numReviews?: number | null;
                    } | null;
                    disrupters?: Array<{
                      __typename?: 'FdrDisrupter';
                      text: string;
                    }> | null;
                    image?: {
                      __typename?: 'FdrImage';
                      alt: string;
                      description?: string | null;
                      copyright?: string | null;
                      rawUrl: string;
                      desaturated?: boolean | null;
                      resized: Array<{
                        __typename?: 'FdrResizedImage';
                        id: string;
                        link: {
                          __typename?: 'FdrLink';
                          openMethod?: Types.FdrLinkOpenMethod | null;
                          legacyUrl?: string | null;
                          type: Types.FdrLinkType;
                          url: string;
                        };
                      }>;
                    } | null;
                    imagesPaginated: {
                      __typename: 'FdrImagesResponse';
                      images?: Array<{
                        __typename?: 'FdrImage';
                        alt: string;
                        description?: string | null;
                        copyright?: string | null;
                        rawUrl: string;
                        desaturated?: boolean | null;
                        resized: Array<{
                          __typename?: 'FdrResizedImage';
                          id: string;
                          link: {
                            __typename?: 'FdrLink';
                            openMethod?: Types.FdrLinkOpenMethod | null;
                            legacyUrl?: string | null;
                            type: Types.FdrLinkType;
                            url: string;
                          };
                        }>;
                      }> | null;
                      pagination: {
                        __typename?: 'FdrPagination';
                        total: number;
                        page: number;
                        perPage: number;
                      };
                    };
                    featureGroups?: Array<{
                      __typename?: 'FdrProductFeatureGroup';
                      id: string;
                      name: string;
                      features: Array<{
                        __typename?: 'FdrProductFeature';
                        name: string;
                        id: string;
                        enabled: boolean;
                      }>;
                    }> | null;
                    resort: {
                      __typename?: 'FdrResort';
                      name: string;
                      id: string;
                      destination: {
                        __typename?: 'FdrDestination';
                        name: string;
                        id: string;
                        country: {
                          __typename?: 'FdrCountry';
                          name: string;
                          id: string;
                        };
                      };
                    };
                  }
                | {
                    __typename: 'FdrRoundTrip';
                    giataCode?: number | null;
                    name: string;
                    fromTo?: string | null;
                    mode?: string | null;
                    id: string;
                    hubData?: {
                      __typename?: 'FdrHubProductData';
                      taReviews: number;
                      taRating: number;
                    } | null;
                    image?: {
                      __typename?: 'FdrImage';
                      alt: string;
                      description?: string | null;
                      copyright?: string | null;
                      rawUrl: string;
                      desaturated?: boolean | null;
                      resized: Array<{
                        __typename?: 'FdrResizedImage';
                        id: string;
                        link: {
                          __typename?: 'FdrLink';
                          openMethod?: Types.FdrLinkOpenMethod | null;
                          legacyUrl?: string | null;
                          type: Types.FdrLinkType;
                          url: string;
                        };
                      }>;
                    } | null;
                    imagesPaginated: {
                      __typename: 'FdrImagesResponse';
                      images?: Array<{
                        __typename?: 'FdrImage';
                        alt: string;
                        description?: string | null;
                        copyright?: string | null;
                        rawUrl: string;
                        desaturated?: boolean | null;
                        resized: Array<{
                          __typename?: 'FdrResizedImage';
                          id: string;
                          link: {
                            __typename?: 'FdrLink';
                            openMethod?: Types.FdrLinkOpenMethod | null;
                            legacyUrl?: string | null;
                            type: Types.FdrLinkType;
                            url: string;
                          };
                        }>;
                      }> | null;
                      pagination: {
                        __typename?: 'FdrPagination';
                        total: number;
                        page: number;
                        perPage: number;
                      };
                    };
                    disrupters?: Array<{
                      __typename?: 'FdrDisrupter';
                      text: string;
                    }> | null;
                    featureGroups?: Array<{
                      __typename?: 'FdrProductFeatureGroup';
                      id: string;
                      name: string;
                      features: Array<{
                        __typename?: 'FdrProductFeature';
                        name: string;
                        id: string;
                        enabled: boolean;
                      }>;
                    }> | null;
                    destination: {
                      __typename?: 'FdrDestination';
                      name: string;
                      id: string;
                      country: {
                        __typename?: 'FdrCountry';
                        name: string;
                        id: string;
                      };
                    };
                  }
                | { __typename: 'FdrAdventureTravel' }
                | null;
            }> | null;
          };
        }
      | { __typename?: 'FdrProductOffersMap' }
      | { __typename?: 'FdrProductRecommender' }
      | { __typename?: 'FdrRawText' }
      | { __typename?: 'FdrSrlButton' }
      | { __typename?: 'FdrThemeGeoRecommender' }
      | { __typename?: 'FdrThemeOfferRecommender' }
      | { __typename?: 'FdrThemeRecommender' }
      | { __typename?: 'FdrUspBoxesComponent' }
      | null
    >;
  };
};

export const FdrProductOfferRecommendationComponentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'FdrProductOfferRecommendationComponent' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'FdrComponentsInput' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'productPage' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
          defaultValue: { kind: 'IntValue', value: '0' },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'productImageCriteria' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'FdrImageCriteria' },
                },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'withCO2Emission' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'Boolean' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fdrComponents' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'FdrComponentsResponse' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'components' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'fdrProductOfferRecommendation',
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...FdrProductOfferRecommendationFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;

/**
 * __useFdrProductOfferRecommendationComponentQuery__
 *
 * To run a query within a React component, call `useFdrProductOfferRecommendationComponentQuery` and pass it any options that fit your needs.
 * When your component renders, `useFdrProductOfferRecommendationComponentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFdrProductOfferRecommendationComponentQuery({
 *   variables: {
 *      input: // value for 'input'
 *      productPage: // value for 'productPage'
 *      productImageCriteria: // value for 'productImageCriteria'
 *      withCO2Emission: // value for 'withCO2Emission'
 *   },
 * });
 */
export function useFdrProductOfferRecommendationComponentQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    FdrProductOfferRecommendationComponentQuery,
    FdrProductOfferRecommendationComponentQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    FdrProductOfferRecommendationComponentQuery,
    FdrProductOfferRecommendationComponentQueryVariables
  >(FdrProductOfferRecommendationComponentDocument, options);
}
export function useFdrProductOfferRecommendationComponentLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    FdrProductOfferRecommendationComponentQuery,
    FdrProductOfferRecommendationComponentQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    FdrProductOfferRecommendationComponentQuery,
    FdrProductOfferRecommendationComponentQueryVariables
  >(FdrProductOfferRecommendationComponentDocument, options);
}
export type FdrProductOfferRecommendationComponentQueryHookResult = ReturnType<
  typeof useFdrProductOfferRecommendationComponentQuery
>;
export type FdrProductOfferRecommendationComponentLazyQueryHookResult =
  ReturnType<typeof useFdrProductOfferRecommendationComponentLazyQuery>;
export type FdrProductOfferRecommendationComponentQueryResult =
  Apollo.QueryResult<
    FdrProductOfferRecommendationComponentQuery,
    FdrProductOfferRecommendationComponentQueryVariables
  >;
